import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDatepickerInputEvent } from '@angular/material';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ClienteService } from 'src/app/services/cliente.service';
import { AdministradorService } from 'src/app/services/administrador.service';
import { TipoTarjeta } from 'src/app/models/tipo-tarjeta';

@Component({
  selector: 'app-ventana-tipo-tarjeta',
  templateUrl: './ventana-tipo-tarjeta.component.html',
  styleUrls: ['./ventana-tipo-tarjeta.component.scss']
})
export class VentanaTipoTarjetaComponent implements OnInit {

  // tarjeta:Tarjeta=new Tarjeta();
  // listTipoTarjeta:TipoTarjeta[]=[];
  formNewTarjeta: FormGroup;

  mensajeError:string;
  showSpinner=false;
  existError=false;

  submitted=false;
  miTipoTarjeta:TipoTarjeta=new TipoTarjeta();

  strTituloFormulario:string;
  miFechaNueva:any;

  btnInsert=false;
  btnEdit=false;

  constructor(private thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) private data: any,private _formBuilder: FormBuilder,public datepipe: DatePipe,
              private miservcio:AdministradorService,private route: ActivatedRoute,private router: Router,) { }

  ngOnInit() {
    // console.log(this.data)
    this.formNewTarjeta = this._formBuilder.group({
      nombre: [Validators.required],
      codExt: [''],
      activo: [''],
      titulo1: [''],
      titulo2: [''],
    })
    // this.formNewTarjeta.controls.codigoTus.disable();
    if(this.data!=null){
      //Edicion
      // this.verDatosTarjeta();
      this.tiposTarjetas();
      // this.formNewTarjeta.controls.tipo.disable();
      // this.formNewTarjeta.controls.numero.disable();
      // this.formNewTarjeta.controls.fCaducidad.disable();
      this.btnEdit=true;
    }
    else{
      this.btnInsert=true;
      //Insercion
      // this.tarjeta.strVinculadoCliente="S";
      this.strTituloFormulario="Nuevo tipo tarjeta";
      this.miTipoTarjeta.strActivo="N";
    }
  }

  changeAct(e){
    // console.log(e)
    if(e.checked){
      this.miTipoTarjeta.strActivo="S";
    }
    else{
      this.miTipoTarjeta.strActivo="N";
    }
  }

  // changeActTitulo1(e){
  //   // console.log(e)
  //   if(e.checked){
  //     this.miTipoTarjeta.strUsaTitulo1="S";
  //   }
  //   else{
  //     this.miTipoTarjeta.strUsaTitulo1="N";
  //   }
  // }

  // changeActTitulo2(e){
  //   // console.log(e)
  //   if(e.checked){
  //     this.miTipoTarjeta.strUsaTitulo2="S";
  //   }
  //   else{
  //     this.miTipoTarjeta.strUsaTitulo2="N";
  //   }
  // }

  tiposTarjetas(){
    this.miservcio.getTiposTarjeta(this.data.intId,"","","").subscribe(datos=>{
      if(datos.miRespuesta.booOK){
        this.miTipoTarjeta=datos.miListTipoTarjeta[0];
      }
    })
  }

  addEvent( event: MatDatepickerInputEvent<Date>) {
    this.miFechaNueva=this.datepipe.transform(event.value, 'yyyy-MM-dd');
  }

  btnInsertar(){
    // console.log(this.miTipoTarjeta)
    this.submitted=true;
    if(this.formNewTarjeta.valid){
      this.showSpinner=true;
      this.miservcio.PutPostTipoTarjeta(this.miTipoTarjeta,0).subscribe(datos=>{
        // console.log(datos)

        this.showSpinner=false;
        if(datos.miRespuesta.booOK){
          this.existError=false;
          this.thisDialogRef.close(datos.miListTipoTarjeta)
        }else{
          this.existError=true;
          this.mensajeError=datos.miRespuesta.strMensaje;
        }
      })
    }
    
  }

  btnEditar(){
    // console.log(this.miTipoTarjeta)
    this.submitted=true;
    if(this.formNewTarjeta.valid){
      this.showSpinner=true;
      this.miservcio.PutPostTipoTarjeta(this.miTipoTarjeta,this.miTipoTarjeta.intId).subscribe(datos=>{
        // console.log(datos)
        this.showSpinner=false;
        if(datos.miRespuesta.booOK){
          this.existError=false;
          this.thisDialogRef.close(datos.miListTipoTarjeta)
        }else{
          this.existError=true;
          this.mensajeError=datos.miRespuesta.strMensaje;
        }
      })
    }
  }

  cancelar(){
    this.thisDialogRef.close();
  }

}
