import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMaterialModule } from './app-material.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule, MatPaginatorIntl } from '@angular/material';
import { CustomPaginator } from './resources/Funciones';
import { DatePipe } from '@angular/common';
import { StorageService } from './services/storage.service';
import { SotaregePagosTusService } from './services/sotarege-pagos-tus.service';
import { ClienteService } from './services/cliente.service';
import { AdministradorService } from './services/administrador.service';
import { AuthAdminGuard } from './guards/authAdminGuard';
import { AuthAdminOperarioGuard } from './guards/auth-admin-operario.guard';
import { AuthClienteGuard } from './guards/auth-cliente.guard';
import { AuthGuard } from './guards/authGuard';
import { LoginComponent } from './components/login/login.component';
import { LoginRecuperacionComponent } from './components/login-recuperacion/login-recuperacion.component';
import { SidemenuComponent } from './components/sidemenu/sidemenu.component';
import { LayoutComponent } from './components/layout/layout.component';
import { InicioComponent } from './components/inicio/inicio.component';
// import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
// import { DetalleRecargaComponent } from './components/detalle-recarga/detalle-recarga.component';
// import { CreateUserComponent } from './components/create-user/create-user.component';
// import { PagoFinalizadoComponent } from './components/pago-finalizado/pago-finalizado.component';
// import { ConfirmacionRegistroComponent } from './components/confirmacion-registro/confirmacion-registro.component';
import { NotificacionComponent } from './components/Ventanas emergentes/notificacion/notificacion.component';
import { VentanaTipoTarjetaComponent } from './components/Ventanas emergentes/ventana-tipo-tarjeta/ventana-tipo-tarjeta.component';
import { DetalleOperarioComponent } from './components/Ventanas emergentes/detalle-operario/detalle-operario.component';
// import { CambiaContrasenaComponent } from './components/cambia-contrasena/cambia-contrasena.component';
import { ListadoComponent } from './components/listado/listado.component';
// import { OperariosComponent } from './components/operarios/operarios.component';
// import { SetPasswordComponent } from './components/operarios/set-password/set-password.component';
import { DetalleClienteComponent } from './components/detalle-cliente/detalle-cliente.component';
import { ListTarjetasComponent } from './components/list-tarjetas/list-tarjetas.component';
// import { HistorialRecargasComponent } from './components/historial-recargas/historial-recargas.component';
// import { ConfiguracionComponent } from './components/configuracion/configuracion.component';
// import { DetalleRecargasComponent } from './components/detalle-recargas/detalle-recargas.component';
// import { TiposTarjetaComponent } from './components/tipos-tarjeta/tipos-tarjeta.component';
// import { VentanaRecargasTarjetaComponent } from './components/ventana-recargas-tarjeta/ventana-recargas-tarjeta.component';
// import { InsertarMitarjetaComponent } from './components/insertar-mitarjeta/insertar-mitarjeta.component';
// import { TarjetasComponent } from './components/tarjetas/tarjetas.component';
// import { MisRecargasComponent } from './components/mis-recargas/mis-recargas.component';
// import { RecargarComponent } from './components/recargar/recargar.component';
// import { DatosClienteComponent } from './components/datos-cliente/datos-cliente.component';
import { CRespuestaService } from './components/login/shared/CRespuesta.service';
import { FooterComponent } from './components/footer/footer.component';
import { ExplotacionesComponent } from './components/Ventanas emergentes/explotaciones/explotaciones.component';
import { RebanosComponent } from './components/Ventanas emergentes/rebanos/rebanos.component';
import { ListadoRebanosComponent } from './components/listado-rebanos/listado-rebanos.component';
import { ListadoEspeciesComponent } from './components/listado-especies/listado-especies.component';
import { ListadoRazasComponent } from './components/listado-razas/listado-razas.component';
// import { TiposTarjetaRecargasComponent } from './components/tipos-tarjeta-recargas/tipos-tarjeta-recargas.component';
// import { DetalleTipoTarjetaRecargaComponent } from './components/detalle-tipo-tarjeta-recarga/detalle-tipo-tarjeta-recarga.component';
// import { TotemsComponent } from './components/totems/totems.component';
// import { DetalleTotemComponent } from './components/detalle-totem/detalle-totem.component';
// import { DetalleTarjetaComponent } from './components/detalle-tarjeta/detalle-tarjeta.component';
// import { RecargasTotemComponent } from './components/recargas-totem/recargas-totem.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    // ToolbarComponent,
    ListadoComponent,
    LoginRecuperacionComponent,
    SidemenuComponent,
    LayoutComponent,
    InicioComponent,
    // ResetPasswordComponent,
    // VentanaErrorComponent,
    // OperariosComponent,
    // SetPasswordComponent,
    // CreateUserComponent,
    DetalleClienteComponent,
    ListTarjetasComponent,
    // EditarTarjetaAdminComponent,
    // HistorialRecargasComponent,
    // DetalleHistorialComponent,
    // ConfiguracionComponent,
    // InsertarMitarjetaComponent,
    // TarjetasComponent,
    // EditarMitarjetaComponent,
    // MisRecargasComponent,
    // RecargarComponent,
    // PagoFinalizadoComponent,
    // EditarAdmintarjetaComponent,
    // DetalleRecargasComponent,
    // DatosClienteComponent,
    // ConfirmacionRegistroComponent,
    NotificacionComponent,
    // TiposTarjetaComponent,
    // DetalleRecargaComponent,
    // VentanaRecargasTarjetaComponent,
    VentanaTipoTarjetaComponent,
    DetalleOperarioComponent,
    // CambiaContrasenaComponent,
    FooterComponent,
    ExplotacionesComponent,
    RebanosComponent,
    ListadoRebanosComponent,
    ListadoEspeciesComponent,
    ListadoRazasComponent,
    // TiposTarjetaRecargasComponent,
    // DetalleTipoTarjetaRecargaComponent,
    // TotemsComponent,
    // DetalleTotemComponent,
    // DetalleTarjetaComponent,
    // RecargasTotemComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatNativeDateModule
  ],
  providers: [StorageService, SotaregePagosTusService, ClienteService, AdministradorService, CRespuestaService, {provide: MatPaginatorIntl, useValue: CustomPaginator() },DatePipe,
              AuthAdminGuard, AuthAdminOperarioGuard, AuthClienteGuard, AuthGuard
             ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
