
<app-layout></app-layout>

<mat-card class="contenidoPagina">
  <h3>Explotaciones ({{numRegistros}} registros)</h3>
</mat-card>

<mat-card class="contenidoPagina matCard2">

<!-- <mat-tab-group color="primary" backgroundColor="primary" (selectedTabChange)="tabChanged($event)">
  <mat-tab label="Vinculadas a clientes">
    <br> -->
    <div class="cabeceraFiltros">
      <div class="min_lupa">
        <img src="../../assets/search-90.png" class="lupa" width="35" height="35">
      </div>
      <div class="max">
        <input type="text" autofocus [(ngModel)]="filtro" id="miFiltro" (keyup)="buscar()" placeholder="Buscar...">
      </div>
      <section>
        <!-- <div class="min">
          <img src="../../assets/add-90.png" width="35" height="35" (click)="nuevo()">
        </div> -->
        <div class="min">
          <!-- <img src="../../assets/excel-96.png" width="35" height="35" (click)="exportAsXLSX()"> -->
        </div>
      </section>     
    </div>
  
    <div>
      <label id="example-radio-group-label" style="font-size: 14px;">Estados </label>
      <br>
      <mat-radio-group
        aria-labelledby="example-radio-group-label"
        class="example-radio-group">
        <mat-radio-button class="example-radio-button" *ngFor="let est of filtrosEstado" [value]="est.strValor" [checked]="est.booChecked" (change)="radioChange($event)" style="font-size: 14px;">
          {{est.strNombre}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <br>
    <mat-table #table [dataSource]="dataSource" matSort class="table-items tablaMaterial">
      <ng-container matColumnDef="intId">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="minCellH"> Nº </mat-header-cell>
        <mat-cell *matCellDef="let objeto; let i = index" class="minCell">  
            {{i + 1}}
        </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="strNombre">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </mat-header-cell>
        <mat-cell *matCellDef="let objeto">  
          <div>{{objeto.strNombre}}</div>
        </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="strCodigo">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Código </mat-header-cell>
          <mat-cell *matCellDef="let objeto"><div>{{objeto.strCodigo}}</div> </mat-cell>
      </ng-container> 
    
      <ng-container matColumnDef="strActivo">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="minCell">  </mat-header-cell>
          <mat-cell *matCellDef="let objeto" class="minCell">
            <!-- <button mat-icon-button [matMenuTriggerFor]="menu">
                <img src="../../assets/more_vert.png" width="23" height="23">
              </button>
              <mat-menu #menu="matMenu">
                <div class="with-icon" (click)="detalleTarjeta(objeto)">
                  <mat-icon>visibility</mat-icon> &nbsp; Ver detalles
                </div>
              </mat-menu> -->
            </mat-cell>
      </ng-container>
      
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let objeto; columns: displayedColumns;"></mat-row>
    </mat-table>
    
    <mat-paginator #paginator [pageSizeOptions]="[50, 100, 150]" ></mat-paginator>
  <!-- </mat-tab> -->

  <!-- <mat-tab label="No Vinculadas a clientes">
    <br>
    <div class="cabeceraFiltros">
      <div class="min_lupa">
        <img src="../../assets/search-90.png" class="lupa" width="35" height="35">
      </div>
      <div class="max">
        <input type="text" autofocus [(ngModel)]="filtro" id="miFiltro" (keyup)="buscar()" placeholder="Buscar...">
      </div>
      <section>
        <div class="min">
          <img src="../../assets/excel-96.png" width="35" height="35" (click)="exportAsXLSX()">
        </div>
      </section>     
    </div>

    <div>
      <label id="example-radio-group-label" style="font-size: 14px;">Estados </label>
      <br>
      <mat-radio-group
        aria-labelledby="example-radio-group-label"
        class="example-radio-group">
        <mat-radio-button class="example-radio-button" *ngFor="let est of filtrosEstado" [value]="est.strValor" [checked]="est.booChecked" (change)="radioChange($event)" style="font-size: 14px;">
          {{est.strNombre}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <br>
    <mat-table #table [dataSource]="dataSource" matSort class="table-items tablaMaterial">
      <ng-container matColumnDef="intId">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="minCellH"> Nº </mat-header-cell>
        <mat-cell *matCellDef="let objeto; let i = index" class="minCell">  
            {{i + 1}}
        </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="datFechaCaducidad">
        <mat-header-cell *matHeaderCellDef mat-sort-header> F. caducidad </mat-header-cell>
        <mat-cell *matCellDef="let objeto">  
          <div *ngIf="objeto.booMuestraFechaCaducidad">{{objeto.datFechaCaducidad | date:'dd-MM-yyyy'}}</div>
        </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="strCodigoTus">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Número </mat-header-cell>
          <mat-cell *matCellDef="let objeto"><div>{{objeto.strCodigoTus}}</div> </mat-cell>
      </ng-container> 
    
      <ng-container matColumnDef="strActivo">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="minCell">  </mat-header-cell>
          <mat-cell *matCellDef="let objeto" class="minCell">
            <button mat-icon-button [matMenuTriggerFor]="menu">
                <img src="../../assets/more_vert.png" width="23" height="23">
              </button>
              <mat-menu #menu="matMenu">
                <div class="with-icon" (click)="detalleTarjeta(objeto)">
                  <mat-icon>visibility</mat-icon> &nbsp; Ver detalles
                </div>
              </mat-menu>
            </mat-cell>
      </ng-container>
      
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let objeto; columns: displayedColumns;"></mat-row>
    </mat-table>
    
    <mat-paginator #paginator [pageSizeOptions]="[50, 100, 150]" ></mat-paginator>
  </mat-tab>
</mat-tab-group>
 -->


</mat-card>

<br><br><br>


<div class="containerLoader" *ngIf="showSpinner">
  <div class="wrapper">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="shadow"></div>
    <div class="shadow"></div>
    <div class="shadow"></div>
  </div>
</div>