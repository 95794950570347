import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthClienteGuard implements CanActivate {
  private rolCliente=environment.IdRolCliente;

  constructor(private router: Router,
    private storageService: StorageService) { }

    canActivate() {
      //console.log(this.storageService.isAuthenticated());
      let loginUserData = this.storageService.getCurrentUser();
      if (this.storageService.isAuthenticated() && this.userAuthorized()) {
        // logged in so return true
        return true;
      }
      this.storageService.logout();
      //this.router.navigate(['/login']);
      window.open(window.location.origin+"/login", "_self")
      return false;
    }

    userAuthorized(){
      let loginUserData = this.storageService.getCurrentUser();
  
      switch (loginUserData.strRolId){
        case this.rolCliente:
          return true;
  
        default:
          return false;
      }
    }
  
}
