
<app-layout></app-layout>

<mat-card class="contenidoPagina">
  <h3>Animales ({{numRegistros}} registros)</h3>
</mat-card>


<mat-card class="contenidoPagina matCard2">
  <div class="cabeceraFiltros">
    <div class="min_lupa">
      <img src="../../assets/search-90.png" class="lupa" width="35" height="35">
    </div>
    <div class="max">
      <input type="text" autofocus [(ngModel)]="filtro" id="miFiltro" (keyup)="buscar()" placeholder="Buscar...">
    </div>
    <section>
      <div class="min">
        <img src="../../assets/add-90.png" width="35" height="35" (click)="nuevo()">
      </div>
      <div class="min">
        <!-- <img src="../../assets/excel-96.png" width="35" height="35" (click)="exportAsXLSX()"> -->
      </div>
    </section>     
  </div>

  <div>
    <label id="example-radio-group-label" style="font-size: 14px;">Estados </label>
    <br>
    <mat-radio-group
      aria-labelledby="example-radio-group-label"
      class="example-radio-group">
      <mat-radio-button class="example-radio-button" *ngFor="let est of filtrosEstado" [value]="est.strValor" [checked]="est.booChecked" (change)="radioChange($event)" style="font-size: 14px;">
        {{est.strNombre}}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <br>

<mat-table #table [dataSource]="dataSource" class="table-items tablaMaterial">

  <ng-container matColumnDef="select">
    <mat-header-cell *matHeaderCellDef class="minCellH">
      <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
      </mat-checkbox>
    </mat-header-cell>
    <mat-cell *matCellDef="let objeto" class="minCell">  
      <mat-checkbox (click)="$event.stopPropagation()"
      (change)="$event ? selection.toggle(objeto) : null"
      [checked]="selection.isSelected(objeto)">
      </mat-checkbox>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="intId">
    <mat-header-cell *matHeaderCellDef class="minCellH"> Nº </mat-header-cell>
    <mat-cell *matCellDef="let objeto; let i = index" class="minCell">  
        {{i + 1}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="strNombre">
    <mat-header-cell *matHeaderCellDef> Nombre </mat-header-cell>
    <mat-cell *matCellDef="let objeto">  
        <div>{{objeto.strNombre}}</div>
      <!-- <button mat-raised-button [ngStyle]="{'background-color':getColor(objeto.strActivo)}">{{objeto.strDni}}</button> -->
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="strEspecieNombre">
      <mat-header-cell *matHeaderCellDef> Especie </mat-header-cell>
      <mat-cell *matCellDef="let objeto"><div>{{objeto.strEspecieNombre}}</div> </mat-cell>
  </ng-container> 

  <ng-container matColumnDef="strRazaNombre">
      <mat-header-cell *matHeaderCellDef> Raza </mat-header-cell>
      <mat-cell *matCellDef="let objeto"> <div>{{objeto.strRazaNombre}}</div> </mat-cell>
  </ng-container>

  <ng-container matColumnDef="strCrotal">
      <mat-header-cell *matHeaderCellDef  class="tablaOcultar3"> Crotal </mat-header-cell>
      <mat-cell *matCellDef="let objeto" class="tablaOcultar3"><div>{{objeto.strCrotal}}</div></mat-cell>
  </ng-container>

  <ng-container matColumnDef="strExplotacionNombre">
    <mat-header-cell *matHeaderCellDef  class="tablaOcultar3"> Explotación </mat-header-cell>
    <mat-cell *matCellDef="let objeto" class="tablaOcultar3"><div>{{objeto.strExplotacionNombre}}</div></mat-cell>
  </ng-container> 

  <ng-container matColumnDef="strRebanoNombre">
    <mat-header-cell *matHeaderCellDef  class="tablaOcultar3"> Rebaño </mat-header-cell>
    <mat-cell *matCellDef="let objeto" class="tablaOcultar3"><div>{{objeto.strRebanoNombre}}</div></mat-cell>
  </ng-container> 

  <ng-container matColumnDef="strActivo">
    <mat-header-cell *matHeaderCellDef class="minCell">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <!-- <img src="../../assets/more_vert.png" width="23" height="23"> -->
        <span class="material-icons" style="color: black;">
          arrow_right_alt
          </span>
      </button>
      <mat-menu #menu="matMenu">
        <div class="with-icon" (click)="moverExplotacion()">
          <!-- <mat-icon>visibility</mat-icon> &nbsp;  -->
          Explotación
        </div>
        <div class="with-icon" (click)="moverRebano()">
          <!-- <mat-icon>visibility</mat-icon> &nbsp;  -->
          Rebaño
        </div>
      </mat-menu>
    </mat-header-cell>
    <mat-cell *matCellDef="let objeto" class="minCell">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <img src="../../assets/more_vert.png" width="23" height="23">
      </button>
      <mat-menu #menu="matMenu">
        <!-- <button mat-menu-item (click)="detalleCliente(objeto)">
          <div><img src="../../assets/visibility.png" width="22" height="22">&nbsp; <span>Ver detalles</span> </div>
        </button> -->
        <div class="with-icon" (click)="detalleCliente(objeto)">
          <mat-icon>visibility</mat-icon> &nbsp; Ver detalles
        </div>
      </mat-menu>
    </mat-cell>
  </ng-container>
  
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <!-- <mat-row *matRowDef="let objeto; columns: displayedColumns;" (click)="cambia(objeto)"></mat-row> -->
  <mat-row *matRowDef="let objeto; columns: displayedColumns;" ></mat-row>
</mat-table>



<!-- <mat-paginator #paginator
    [length]="dataSource.data.length"
    [pageIndex]="0"
    [pageSize]="50"
    [pageSizeOptions]="[50, 100, 250]">
</mat-paginator> -->

<mat-paginator #paginator [pageSizeOptions]="[50, 100, 150]" ></mat-paginator>



</mat-card>

<br><br><br>

<div class="containerLoader" *ngIf="showSpinner">
  <div class="wrapper">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="shadow"></div>
    <div class="shadow"></div>
    <div class="shadow"></div>
  </div>
</div>