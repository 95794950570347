<div class="miFormulario">
  <h3 class="titulo">{{strTituloFormulario}}</h3>
  <br>

  <form>
    <ul class="formularioData">
      <form [formGroup]="formNewTarjeta">
        <li>
          <mat-form-field>
            <input matInput formControlName="nombre" [(ngModel)]="miTipoTarjeta.strNombre" name="strNombre" placeholder="Nombre" required>
            <mat-hint class="formatError" style="color: red;" *ngIf="submitted && formNewTarjeta.controls.nombre.hasError('required')">*Campo necesario</mat-hint>
          </mat-form-field>
        </li>
        <li>
          <mat-form-field>
            <input matInput formControlName="codExt" [(ngModel)]="miTipoTarjeta.strIdExterno" mame="strIdExterno" placeholder="Nombre externo">
            
          </mat-form-field>
        </li>
        <li><br></li>
        <!-- <li class="cellSlide">
          <mat-slide-toggle formControlName="titulo1" (change)="changeActTitulo1($event)" [checked]="miTipoTarjeta.strUsaTitulo1 === 'S' ? true : false">¿Título 1?</mat-slide-toggle>
        </li>
        <li class="cellSlide">
          <mat-slide-toggle formControlName="titulo2" (change)="changeActTitulo2($event)" [checked]="miTipoTarjeta.strUsaTitulo2 === 'S' ? true : false">¿Título 2?</mat-slide-toggle>
        </li> -->
        <li class="cellSlide">
          <mat-slide-toggle id="miActivo" formControlName="activo" (change)="changeAct($event)" [checked]="miTipoTarjeta.strActivo === 'S' ? true : false">¿Tarjeta activa?</mat-slide-toggle>
        </li>
      </form>
    </ul>
      
    <div class="msgError" *ngIf="existError">
      {{mensajeError}}
    </div>
    <br>

    <div class="form-actions">
      <div class="text-right">
        <button mat-raised-button (click)="btnInsertar()" class="btn-confirm" *ngIf="btnInsert">Guardar</button>
        <button mat-raised-button (click)="btnEditar()" class="btn-confirm" *ngIf="btnEdit">Guardar</button>
        <button mat-raised-button (click)="cancelar()" class="btn-cancel">Cancelar</button>
      </div>
    </div>

    <br>
   
  </form>
</div>

<div class="containerLoader" *ngIf="showSpinner">
  <div class="wrapper">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="shadow"></div>
    <div class="shadow"></div>
    <div class="shadow"></div>
  </div>
</div>