import { Component, OnInit,ViewChild, HostListener, ElementRef } from '@angular/core';
import {MatTableDataSource, MatSort, DateAdapter,MatPaginator, MatDialog, MatRadioChange} from '@angular/material';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';
import {FormControl} from '@angular/forms';
import {MAT_DATE_LOCALE} from '@angular/material';
import { DataSource, SelectionModel } from '@angular/cdk/collections';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge } from 'rxjs';
import { DatePipe} from '@angular/common';
import { ClaveValor } from './clave-valor';
import { Cliente } from 'src/app/models/cliente';
import { AuxEstado } from 'src/app/models/aux-estado';
import { StorageService } from 'src/app/services/storage.service';
import { AdministradorService } from 'src/app/services/administrador.service';
import { Animal } from 'src/app/models/ganaderia/animal';
import { ExplotacionesComponent } from '../Ventanas emergentes/explotaciones/explotaciones.component';
import { NotificacionComponent } from '../Ventanas emergentes/notificacion/notificacion.component';
import { RebanosComponent } from '../Ventanas emergentes/rebanos/rebanos.component';

@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.scss'],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'es' }]
})
export class ListadoComponent implements OnInit {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  // @ViewChild(MatSort, {static: false}) sort: MatSort;

  showSpinner: boolean = false;
  data:Animal[]=[];
  dataTodo:Animal[]=[];
  displayedColumns = ['select','intId','strNombre','strEspecieNombre','strRazaNombre','strCrotal', 'strExplotacionNombre', 'strRebanoNombre', 'strActivo'];
  dataSource ;

  filtro:string;
  listFiltrada:Animal[]=[];

  dataExcel:datosExcelListado[]=[];
  teclaAlt:any;
  otraTecla:any;

  idInst:any;
  numRegistros:any;
  filtrosEstado:AuxEstado[]=[];
  selectEstado:any;
  selection = new SelectionModel<Animal>(true, []);
  listMoverAnimales:Animal[]=[];

  constructor(private storageService: StorageService,private miservicio:AdministradorService,public datepipe: DatePipe,
    private dateAdapter: DateAdapter<Date>,public dialog: MatDialog,private router: Router) {
    this.dateAdapter.setLocale('es');   
   }

  ngOnInit() {
    this.showSpinner=true;
    this.filtrosEstado.push({intId:1, strNombre:"Activos", strValor:"S", booChecked:true}, {intId:2, strNombre:"Inactivos", strValor:"N", booChecked:false}, {intId:3, strNombre:"Todos", strValor:"T", booChecked:false});
    this.selectEstado=1;
    // this.paginator._intl.itemsPerPageLabel = 'Elementos por pagina';
    // this.loginUserData = this.storageService.getCurrentUser();
    this.listar();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  moverExplotacion(){
    this.listMoverAnimales=this.selection["_selected"];

    let dialogRef = this.dialog.open(ExplotacionesComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result =>{
      if(result!=undefined){
        this.moverAnimales(1,result);
      }
    })
  }

  moverRebano(){
    this.listMoverAnimales=this.selection["_selected"];

    let dialogRef = this.dialog.open(RebanosComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result =>{
      if(result!=undefined){
        this.moverAnimales(2,result);
      }
    })
  }

  moverAnimales(intTipoMovimiento,intIdNuevo){
    this.miservicio.MoverAnimales(intTipoMovimiento,intIdNuevo,this.listMoverAnimales).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOK){

        this.data=datos.miListAnimales;  
        this.listFiltrada=datos.miListAnimales; 
        this.dataTodo=this.data;
        this.data=this.data.filter(i=>i.strActivo.toLowerCase()==="S".toLowerCase());
        this.numRegistros=this.data.length;
        this.listFiltrada=this.data;
        this.dataSource=new MatTableDataSource(this.data);
        this.dataSource.paginator=this.paginator;
        this.data=this.dataTodo;

        this.windowNotif(false,false,true,"Se han guardado los datos correctamente",'');
      }
      else{
        this.windowNotif(false,true,false,"Se ha producido un error",'');
      }
    })
  }

  detalleCliente(animal:Animal){
    let extra: NavigationExtras = {
      queryParams: {
        'intId':animal.intId,
        // 'intTarjeta':0,
      }
    }
    this.router.navigate(["detalle"],extra);
  }

  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) { 
    this.otraTecla=event.keyCode;  
    
    if(this.teclaAlt===78 && this.otraTecla===18){
      // this.nuevo();
    }else if(this.teclaAlt===88 && this.otraTecla===18){
      // this.exportAsXLSX();
    }else if(this.teclaAlt===66 && this.otraTecla===18){
      var myEl = document.getElementById('miFiltro');
      myEl.focus();
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    //alert(event.keyCode)
    this.teclaAlt=event.keyCode;    
  }

  public listar(){
    // let miFecha=this.datepipe.transform(this.date.value, 'yyyy-MM-dd');
    // this.dataSource = new MyTableDataSource(this.paginator, this.sort);
    this.miservicio.getAnimales(0,'',0,0,0,'','','',0,'',0,0,0,0,'').subscribe(datos=>{
      // console.log(datos)
      this.showSpinner=false;
      if(datos.miRespuesta.booOK){
        this.data=datos.miListAnimales;  
        this.listFiltrada=datos.miListAnimales; 

        this.dataTodo=this.data;
        this.data=this.data.filter(i=>i.strActivo.toLowerCase()==="S".toLowerCase());
        this.numRegistros=this.data.length;
        this.listFiltrada=this.data;
        // this.dataSource = new MyTableDataSource(this.paginator, this.sort);
        this.dataSource=new MatTableDataSource(this.data);
        // this.dataSource.sort = this.sort;
        this.dataSource.paginator=this.paginator;
        this.data=this.dataTodo;
      }
      
    })
  }

  nuevo(){
    let extra: NavigationExtras = {
      queryParams: {
        'intId':0,
      }
    }
    this.router.navigate(["detalle"],extra);
  }

  radioChange(event: MatRadioChange) {
    switch(event.value){
      case "S":
        this.selectEstado=1;
        break;

      case "N":
        this.selectEstado=2;
        break;

        case "T":
        this.selectEstado=3;
        break;
    }

    this.buscar();
}

  getColor(e) { 
    switch (e) {
      case 'S':
        // return '#00b300';
        return '#5cd65c';
      case 'N':
        // return '#ff3333';
        return '#ff6666';
      // case 3:
      //   return '#00b300';
    }
  }

  buscar(){  
    this.dataTodo=this.data;
    if(this.filtro!=null){
      this.data=this.data.filter(i=>i.strNombre.replace(/\s/g, "").toLowerCase().includes(this.filtro.replace(/\s/g, "").toLowerCase()) || 
                                 i.strRazaNombre.replace(/\s/g, "").toLowerCase().includes(this.filtro.replace(/\s/g, "").toLowerCase()) || 
                                 i.strObservaciones.replace(/\s/g, "").toLowerCase().includes(this.filtro.replace(/\s/g, "").toLowerCase()) || 
                                 i.strEspecieNombre.replace(/\s/g, "").toLowerCase().includes(this.filtro.replace(/\s/g, "").toLowerCase()) || 
                                 i.strEtapaNombre.replace(/\s/g, "").toLowerCase().includes(this.filtro.replace(/\s/g, "").toLowerCase()) || 
                                 i.strCrotal.replace(/\s/g, "").toLowerCase().includes(this.filtro.replace(/\s/g, "").toLowerCase()));
      
    }

    if(this.selectEstado!=0){
      switch(this.selectEstado){
        case 1:
          this.data=this.data.filter(i=>i.strActivo.toLowerCase()==="S".toLowerCase());
          break;
  
        case 2:
          this.data=this.data.filter(i=>i.strActivo.toLowerCase()==="N".toLowerCase());
          break;
  
        case 3:
        break;
  
      }
    }

    this.numRegistros=this.data.length;
    this.listFiltrada=this.data;
    // this.dataSource = new MyTableDataSource(this.paginator, this.sort);
    this.dataSource=new MatTableDataSource(this.data);
    // this.dataSource.sort = this.sort;
    this.dataSource.paginator=this.paginator;
    this.data=this.dataTodo;
  }

  // exportAsXLSX():void {
  //   this.listFiltrada.forEach(e => {
  //     this.dataExcel.push({
  //       Nombre:e.strNombre,
  //       Apellidos:e.strApellidos,
  //       Dni:e.strDni,
  //       Direccion:e.strDireccion,
  //       Poblacion:e.strPoblacion,
  //       CP:e.strCp,
  //       Provincia:e.strProvincia,
  //       Pais:e.strPais,
  //       Telefono1:e.strTelefono1,
  //       Telefono2:e.strTelefono2,
  //       Email:e.strEmail,
  //       Activo:e.strActivo
  //     })
  //   });
  //   this.miservicio.exportAsExcelFile(this.dataExcel, 'Listado de clientes');

  //   this.dataExcel.splice(0, this.dataExcel.length)
  // }

  windowNotif(booBoton, booBtnError, btnOK, strMensaje, strParrafo2){
    let dialogRef = this.dialog.open(NotificacionComponent, {
      width: '600px',
      data:{
        texto:strMensaje,
        texto2:strParrafo2,
        boton:booBoton,
        btnError:booBtnError,
        btnOK:btnOK
      }
    });
    dialogRef.afterClosed().subscribe(result =>{
      return result;
    })
  }

  deleteFilter(){
    //Refrescar la pagina
    window.location.reload();
  }

}

export interface datosExcelListado {
  Nombre:any;
  Apellidos:any;
  Dni:any;
  Direccion:any;
  Poblacion:any;
  CP:any;
  Provincia:any;
  Pais:any;
  Telefono1:any;
  Telefono2:any;
  Email:any;
  Activo:any;
}