<section>
  Tu sesión ha caducado...
</section>
<br>
<section>
  <img src="../../assets/icon_confused.png">
</section>
<br>
<section>
  <button mat-raised-button color="warn" (click)="logout()">Aceptar</button>
</section>
