// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //baseUrl : 'http://192.168.1.135/resttuswa',
  // baseUrl : 'http://localhost:11577',
  // baseUrl:'https://webservice.infitec.es/resttuswa',

  //Roles
  IdRolAdmin:"1",
  IdRolCliente:"2",
  IdRolOperario:"3",

  //Cookies
  strCookieRecargaId:"cooNumber",

  //Pasarela
  estadoPasarelaPendiente:'PAS/PEN',
  estadoPasarelaOk:'PAS/OK',
  estadoPasarelaError:'PAS/ERROR',

  //Titulos de tarjeta
  // strTitulo1:"Abono temporal",
  // strTitulo2:"Monedero",
  // intTitulo1:1,
  // intTitulo2:2,

  //Recargas Totem
  TPVPC_PAGO_OK:"TPVPC_PAGO_OK",
  TPVPC_PAGO_ERROR:"TPVPC_PAGO_ERROR",
  TPVPC_DEVOLUCION_OK:"TPVPC_DEVOLUCION_OK",
  TPVPC_DEVOLUCION_ERROR:"TPVPC_DEVOLUCION_ERROR",

  VINCULADO_OK:"VINCULADO_OK",
  VINCULADO_ERROR:"VINCULADO_ERROR"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
