<div class="miFormulario">
  <h3 class="titulo">{{strTituloFormulario}}</h3>
  <br>

  <form>
    <ul class="formularioData">
      <form [formGroup]="formOperario">
        <li>
          <mat-form-field>
            <input matInput formControlName="nombre" [(ngModel)]="miOperario.strNombre" name="strNombre" placeholder="Nombre" required>
            <mat-hint class="formatError" style="color: red;" *ngIf="submitted && formOperario.controls.nombre.hasError('required')">*Campo necesario</mat-hint>
          </mat-form-field>
        </li>
        <li>
          <mat-form-field>
            <input matInput formControlName="apellidos" [(ngModel)]="miOperario.strApellidos" mame="strApellidos" placeholder="Apellidos" required>
            <mat-hint class="formatError" style="color: red;" *ngIf="submitted && formOperario.controls.apellidos.hasError('required')">*Campo necesario</mat-hint>
          </mat-form-field>
        </li>
        <li>
          <mat-form-field>
            <input matInput formControlName="dni" [(ngModel)]="miOperario.strDni" mame="strDni" placeholder="D.N.I." required>
            <mat-hint class="formatError" style="color: red;" *ngIf="submitted && formOperario.controls.dni.hasError('required')">*Campo necesario</mat-hint>
          </mat-form-field>
        </li>
        <li>
          <mat-form-field>
            <input matInput formControlName="email" [(ngModel)]="miOperario.strEmail" mame="strEmail" placeholder="Usuario" required>
            <mat-hint class="formatError" style="color: red;" *ngIf="submitted && formOperario.controls.email.hasError('required')">*Campo necesario</mat-hint>
            <mat-hint class="formatError" style="color: red;" *ngIf="submitted && formOperario.controls.email.hasError('pattern')">El formato no es válido  </mat-hint>
          </mat-form-field>
        </li>
        <li *ngIf="isNew">
          <mat-form-field>
            <input matInput formControlName="password" [(ngModel)]="miOperario.strPassword" mame="strPassword" placeholder="Contraseña">
            <mat-hint class="formatError" style="color: red;" *ngIf="submitted && formOperario.controls.password.hasError('required')">*Campo necesario</mat-hint>
            <mat-hint class="formatError" style="color: red;" *ngIf="submitted && formOperario.controls.password.hasError('pattern')">El formato no es válido  </mat-hint>
          </mat-form-field>
        </li>
        <li *ngIf="isNew">
          <mat-form-field>
            <input matInput formControlName="confirmaPass" [(ngModel)]="miOperario.strConfirmPassword" mame="strConfirmPassword" placeholder="Confirme contraseña">
            <mat-hint class="formatError" style="color: red;" *ngIf="submitted && formOperario.controls.confirmaPass.hasError('required')">*Campo necesario</mat-hint>
            <mat-hint class="formatError" style="color: red;" *ngIf="submitted && formOperario.controls.confirmaPass.hasError('pattern')">El formato no es válido  </mat-hint>
          </mat-form-field>
        </li>
        <li>
          <mat-form-field>
            <input matInput formControlName="tel1" [(ngModel)]="miOperario.strTelefono1" mame="strTelefono1" placeholder="Teléfono">
          </mat-form-field>
        </li>
        <li><br></li>
        <li *ngIf="isNew"><br></li>
        <li class="cellSlide" *ngIf="isEdit">
          <mat-slide-toggle id="miActivo" formControlName="activo" (change)="changeAct($event)" [checked]="miOperario.strActivo === 'S' ? true : false">¿Operario activo?</mat-slide-toggle>
        </li>
      </form>
    </ul>
      
    <div class="msgError" *ngIf="existError">
      {{mensajeError}}
    </div>
    <br>

    <div class="form-actions">
      <div class="text-right">
        <button mat-raised-button (click)="btnInsertar()" class="btn-confirm" *ngIf="btnInsert">Guardar</button>
        <button mat-raised-button (click)="btnEditar()" class="btn-confirm" *ngIf="btnEdit">Guardar</button>
        <button mat-raised-button (click)="cancelar()" class="btn-cancel">Cancelar</button>
      </div>
    </div>

    <br>
   
  </form>
</div>

<div class="containerLoader" *ngIf="showSpinner">
  <div class="wrapper">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="shadow"></div>
    <div class="shadow"></div>
    <div class="shadow"></div>
  </div>
</div>
