import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AdministradorService } from 'src/app/services/administrador.service';

@Component({
  selector: 'app-rebanos',
  templateUrl: './rebanos.component.html',
  styleUrls: ['./rebanos.component.scss']
})
export class RebanosComponent implements OnInit {

  intIdRebano:any;
  isSelected=false;

  constructor(private thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) private data: any,
              private miservcio:AdministradorService) { }

  ngOnInit() {
    // console.log(this.data)
  }

  guardar(){
    if(this.intIdRebano!=undefined){
      this.isSelected=false;
      this.thisDialogRef.close(this.intIdRebano);
    }
    else{
      this.isSelected=true;
    }
  }

  selectRebano(){
    // console.log(this.intIdRebano)
  }

  cancelar(){
    this.thisDialogRef.close();
  }

}
