export class Animal {

    public intId?:number;
    public datFechaRegistro?:Date;
    public datFechaActualizacion?:Date;
    public strNombre?:string;
    public intIdEspecie?:number;
    public intIdRaza?:number;
    public intIdEtapa?:number;
    public strCrotal?:string;
    public strDib?:string;
    public strDibOrigen?:string;
    public intIdSexo?:number;
    public datFechaNacimiento?:Date;
    public datFechaOficial?:Date;
    public intIdExplotacion?:number;
    public intIdRebano?:number;
    public intIdAnimalPadre?:number;
    public intIdAnimalMadre?:number;
    public strPais?:string;
    public strObservaciones?:string;
    public strActivo?:string;
    public strEspecieNombre?:string;
    public strRazaNombre?:string;
    public strEtapaNombre?:string;
    public strExplotacionNombre?:string;
    public strRebanoNombre?:string;

}
