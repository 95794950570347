import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { StorageService } from './storage.service';
import { ClienteResult } from '../models/cliente-result';
import { TarjetaResult } from '../models/tarjeta-result';
import { Tarjeta } from '../models/tarjeta';
import { RecargaResult } from '../models/recarga-result';
import { Configuraciones } from '../models/configuraciones';
import { Operador } from '../models/operador';
import { OperarioResult } from '../models/operario-result';
import { Cliente } from '../models/cliente';
import { TipoTarjetaRecargaResult } from '../models/tipo-tarjeta-recarga-result';
import { TotemResult } from '../models/totem-result';
import { RecargaTotemResult } from '../models/recarga-totem-result';
import { TipoTarjetaResult } from '../models/tipo-tarjeta-result';
import { AnimalResult } from '../models/ganaderia/animal-result';
import { RazaResult } from '../models/ganaderia/raza-result';
import { ExplotacionResult } from '../models/ganaderia/explotacion-result';
import { RebanoResult } from '../models/ganaderia/rebano-result';
import { EspecieResult } from '../models/ganaderia/especie-result';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class AdministradorService {

  constructor(private http: HttpClient, private storageService: StorageService ) {}

  // public url: string = environment.baseUrl+'/api/';
  url=this.storageService.getCurrentUrlRest()+'/api/';
  private strToken=this.storageService.getCurrentToken();

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer ' + this.strToken  })
  };

  findAll():Observable<Cliente[]>{ 
    return this.http.get<Cliente[]>(this.url + 'cliente',  this.httpOptions);
  }
  
  findCliente(intId:number):Observable<ClienteResult>{
    let dato='intClienteId='+intId;
    let urlCompleta=this.url+'cliente?'+dato;
 
    return this.http.get<ClienteResult>(urlCompleta,  this.httpOptions);
  }

  findTarjeta(intClienteId:number,intTarjetaId,strVinculadoACliente):Observable<TarjetaResult>{
    let dato='intClienteId='+intClienteId+'&intTarjetaId='+intTarjetaId+'&strVinculadoACliente='+strVinculadoACliente;
    let urlCompleta=this.url+'tarjeta?'+dato;
 
    return this.http.get<TarjetaResult>(urlCompleta,  this.httpOptions);
  }

  putActivo(mistrUserIdActualizacion:string,estadoSalvar,intId:number):Observable<ClienteResult>{
    var body = '{"intId": '+ intId +', "strActivo":"'+ estadoSalvar +'", "strUserIdActualizacion":"'+ mistrUserIdActualizacion +'"}';

    return this.http.put<ClienteResult>(this.url + 'cliente', body, this.httpOptions)
  }

  putTarjeta(tarjeta:Tarjeta, intClienteId, strVinculadoACliente):Observable<TarjetaResult>{
    let dato = 'intClienteId='+intClienteId+'&strVinculadoACliente='+strVinculadoACliente;
    let urlCompleta = this.url + 'tarjeta?' + dato;
    // var body = '{"strNombre": "'+ tarjeta.strNombre +'", "strCodigoTus":"'+ tarjeta.strCodigoTus +'", "strActivo":"S", "strUserIdActualizacion":"'+ strUserIdActualizacion +'", "intClienteId":'+ intClienteId +'}';
    var body=JSON.stringify(tarjeta);
    
    return this.http.put<TarjetaResult>(urlCompleta, body, this.httpOptions)    
  }

  getRecargas(intClienteId,intTarjetaId,intRecargaId,strCerrada,strVinculadoTarjeta,strFechaDesde,strFechaHasta):Observable<RecargaResult>{
    let dato='intClienteId='+ intClienteId +'&intTarjetaId='+ intTarjetaId +'&intRecargaId='+intRecargaId + '&strCerrada=' + strCerrada;
    dato+='&strVinculadoTarjeta='+strVinculadoTarjeta+'&strFechaDesde='+strFechaDesde+'&strFechaHasta='+strFechaHasta;
    let urlCompleta=this.url+'recarga?'+dato;
 
    return this.http.get<RecargaResult>(urlCompleta,  this.httpOptions);
  }

  getRecargasTotems(intId, strTotem, strCodigoTarjetaTUS, strFechaDesde, strFechaHasta):Observable<RecargaTotemResult>{
    let dato='intId='+intId+'&strTotem='+strTotem+'&strCodigoTarjetaTUS='+strCodigoTarjetaTUS+'&strFechaDesde='+strFechaDesde+'&strFechaHasta='+strFechaHasta;
    let urlCompleta=this.url+'RecargaTotem?'+dato;
 
    return this.http.get<RecargaTotemResult>(urlCompleta,  this.httpOptions);
  }

  getConfiguraciones(strUserId):Observable<Configuraciones[]>{
    let dato='strUserId='+ strUserId;
    let urlCompleta=this.url+'configuracion?'+dato;
 
    return this.http.get<Configuraciones[]>(urlCompleta,  this.httpOptions);
  }

  getNombreApp():Observable<Configuraciones[]>{
    let urlCompleta=this.url+'configuracion?';
 
    return this.http.get<Configuraciones[]>(urlCompleta,  this.httpOptions);
  }

  addOperador(operador:Operador){
    let dato = '{"TipoUsuario": '+ operador.intTipoUsuario +', "Email": "'+ operador.strEmail +'", "Password":"'+ operador.strPassword;
    dato  += '", "ConfirmPassword":"'+ operador.strConfirmPassword +'", "strUsuarioNombre":"'+ operador.strNombre +'", "strUsuarioApellidos":"'+ operador.strApellidos; 
    dato += '", "strUsuarioDni":"'+ operador.strDni +'", "UserIdActualizacion":"' + this.storageService.getCurrentSession().user.strUserId + '" }';
    let json=dato;
    let params = json; 
    let headers = new HttpHeaders().set('Content-Type','application/json');     
    return this.http.post(this.url + 'account/register', params, {headers: headers});
  }

  getOperarios(intOperarioId):Observable<OperarioResult>{
    let dato='intOperarioId='+ intOperarioId;
    let urlCompleta=this.url+'Operario?'+dato;
    return this.http.get<OperarioResult>(urlCompleta,  this.httpOptions);
  }

  putOperario(o):Observable<OperarioResult>{
    var body=JSON.stringify(o);
    
    return this.http.put<OperarioResult>(this.url + 'operario', body, this.httpOptions)
  }

  putCondfiguracion(c:Configuraciones,TipoDato){
    var jsonString = JSON.stringify(c);

    return this.http.put(this.url + 'configuracion', jsonString, this.httpOptions)
  }

  putPasarela(c:Configuraciones,TipoDato){
    var body = '{"TipoDato":"'+ TipoDato +'","intId": '+ c.intId +', "strTpvVirIdComercio":"'+ c.strTpvVirIdComercio +'", "strTpvVirIdTerminal":"'+ c.strTpvVirIdTerminal +'", "strTpvVirMoneda":"'+ c.strTpvVirMoneda +'", "strTpvVirCifrado":"'+ c.strTpvVirCifrado +'", "strTpvVirClaveSecreta":"'+ c.strTpvVirClaveSecreta +'", "strTpvVirUrlPeticion":"'+ c.strTpvVirUrlPeticion +'", "strTpvVirUrlNotificacion":"'+ c.strTpvVirUrlNotificacion +'", "strTpvVirUrlOk":"'+ c.strTpvVirUrlOk +'", "strTpvVirUrlOkError":"'+ c.strTpvVirUrlOkError +'", "strUserId":"'+ c.strUserId +'"}';
    
    return this.http.put(this.url + 'configuracion', body, this.httpOptions)
  }
  
  putServidor(c:Configuraciones,TipoDato){
    var body = '{"TipoDato":"'+ TipoDato +'","intId": '+ c.intId +', "strCorreoUsuario":"'+ c.strCorreoUsuario +'", "strCorreoClave":"'+ c.strCorreoClave +'", "strCorreoSmtp":"'+ c.strCorreoSmtp +'", "strCorreoSmtpPort":"'+ c.strCorreoSmtpPort +'", "strCorreoSmtpSsl":"'+ c.strCorreoSmtpSsl +'", "strUserId":"'+ c.strUserId +'"}';
    
    return this.http.put(this.url + 'configuracion', body, this.httpOptions)
  }

  postFile(fileToUpload: File){
    const endpoint = 'http://localhost:3000/api/upload';
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    let headers = new HttpHeaders().set('Content-Type','application/json');
    
    return this.http.post(endpoint, formData, {headers: headers})
  }

  getImage(imageUrl: string): Observable<Blob> {
    return this.http.get(imageUrl, { responseType: 'blob' });
  }

  //TiposTarjeta

  getTiposTarjeta(intId,strTipoExt,strNombre,strActivo):Observable<TipoTarjetaResult>{
    let dato='intId='+intId+'&strTipoExt='+strTipoExt+'&strNombre='+strNombre+'&strActivo='+strActivo;
    let urlCompleta=this.url+'TipoTarjeta?'+dato;
 
    return this.http.get<TipoTarjetaResult>(urlCompleta,  this.httpOptions);
  }

  // getTiposTarjetas(intId,strTipoExt,strNombre,strActivo):Observable<TipoTarjetaResult>{
  //   let dato='intId='+intId+'&strTipoExt='+strTipoExt+'&strNombre='+strNombre+'&strActivo='+strActivo;
  //   let urlCompleta=this.url+'TipoTarjeta?'+dato;
 
  //   return this.http.get<TipoTarjetaResult>(urlCompleta,  this.httpOptions);
  // }

  PutPostTipoTarjeta(tipotarjeta, intTipoTarjetaId):Observable<TipoTarjetaResult>{
    let dato = 'intTipoTarjetaId='+intTipoTarjetaId;
    let urlCompleta = this.url + 'TipoTarjeta?' + dato;
    // var body = '{"strNombre": "'+ tarjeta.strNombre +'", "strCodigoTus":"'+ tarjeta.strCodigoTus +'", "strActivo":"S", "strUserIdActualizacion":"'+ strUserIdActualizacion +'", "intClienteId":'+ intClienteId +'}';
    var body=JSON.stringify(tipotarjeta);
    
    return this.http.post<TipoTarjetaResult>(urlCompleta, body, this.httpOptions)    
  }

  //TiposTarjetaRecarga
  getTiposTarjetasRecargas(intId,intImporte,strActivo):Observable<TipoTarjetaRecargaResult>{
    let dato='intId='+intId+'&intImporte='+intImporte+'&strActivo='+strActivo;
    let urlCompleta=this.url+'TipoTarjetaRecarga?'+dato;
 
    return this.http.get<TipoTarjetaRecargaResult>(urlCompleta,  this.httpOptions);
  }

  PutPostTipoTarjetaRecargas(tipotarjeta, intTipoTarjetaId):Observable<TipoTarjetaRecargaResult>{
    let dato = 'intTipoTarjetaId='+intTipoTarjetaId;
    let urlCompleta = this.url + 'TipoTarjetaRecarga?' + dato;
    var body=JSON.stringify(tipotarjeta);
    
    return this.http.post<TipoTarjetaRecargaResult>(urlCompleta, body, this.httpOptions)    
  }

  //TOTEM
  getTotems(intId,strAlias,intTerminal,strActivo):Observable<TotemResult>{
    let dato='intId='+intId+'&strAlias='+strAlias+'&intTerminal='+intTerminal+'&strActivo='+strActivo;
    let urlCompleta=this.url+'Totem?'+dato;
 
    return this.http.get<TotemResult>(urlCompleta,  this.httpOptions);
  }

  PutPostTotem(tipotarjeta, intId):Observable<TotemResult>{
    let dato = 'intId='+intId;
    let urlCompleta = this.url + 'Totem?' + dato;
    var body=JSON.stringify(tipotarjeta);
    
    return this.http.post<TotemResult>(urlCompleta, body, this.httpOptions)    
  }


  //*********************************************************************************************************/
  //GANADERIA

  getAnimales(intId, strFechaRegistro, intIdEspecie, intIdRaza, intIdEtapa, strCrotal, strDib, strDibOrigen, intIdSexo, strFechaNacimiento, intIdExplotacion,
              intIdRebano, intIdAminalMadre, intIdAnimalPadre, strActivo):Observable<AnimalResult>{
    
    let dato='intId='+intId+'&strFechaRegistro='+strFechaRegistro+'&intIdEspecie='+intIdEspecie+'&intIdRaza='+intIdRaza+'&intIdEtapa='+intIdEtapa+'&strCrotal='+strCrotal;
    dato+='&strDib='+strDib+'&strDibOrigen='+strDibOrigen+'&intIdSexo='+intIdSexo+'&strFechaNacimiento='+strFechaNacimiento+'&intIdExplotacion='+intIdExplotacion;
    dato+='&intIdRebano='+intIdRebano+'&intIdAminalMadre='+intIdAminalMadre+'&intIdAnimalPadre='+intIdAnimalPadre+'&strActivo='+strActivo;

    return this.http.get<AnimalResult>(this.url+'Animal?'+dato,  this.httpOptions);
  }

  getRazas(intId, intIdEspecie, strNombre, strActivo):Observable<RazaResult>{

    let dato='intId='+intId+'&intIdEspecie='+intIdEspecie+'&strNombre='+strNombre+'&strActivo='+strActivo;

    return this.http.get<RazaResult>(this.url+'Raza?'+dato,  this.httpOptions);
  }

  PutPostAnimal(animal, intId):Observable<AnimalResult>{
    let dato = 'intId='+intId;
    let urlCompleta = this.url + 'Animal?' + dato;
    var body=JSON.stringify(animal);
    
    return this.http.post<AnimalResult>(urlCompleta, body, this.httpOptions)    
  }


  getExplotaciones(intId, strCodigo, strNombre, strActivo):Observable<ExplotacionResult>{

    let dato='intId='+intId+'&strCodigo='+strCodigo+'&strNombre='+strNombre+'&strActivo='+strActivo;

    return this.http.get<ExplotacionResult>(this.url+'Explotacion?'+dato,  this.httpOptions);
  }


  MoverAnimales(intTipoMovimiento, intIdNuevo, listAnimales):Observable<AnimalResult>{
    let dato = 'intTipoMovimiento='+intTipoMovimiento+'&intIdNuevo='+intIdNuevo;
    let urlCompleta = this.url + 'Animal/moverAnimales?' + dato;
    var body=JSON.stringify(listAnimales);
    
    return this.http.post<AnimalResult>(urlCompleta, body, this.httpOptions)    
  }

  getRebanos(intId, intIdTipo, strNombre, strActivo):Observable<RebanoResult>{

    let dato='intId='+intId+'&intIdTipo='+intIdTipo+'&strNombre='+strNombre+'&strActivo='+strActivo;

    return this.http.get<RebanoResult>(this.url+'Rebano?'+dato,  this.httpOptions);
  }

  getEspecies(intId, strNombre, strActivo):Observable<EspecieResult>{

    let dato='intId='+intId+'&strNombre='+strNombre+'&strActivo='+strActivo;

    return this.http.get<EspecieResult>(this.url+'Especies?'+dato,  this.httpOptions);
  }




  public exportAsExcelFile(json: any[], excelFileName: string): void {
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    //console.log('worksheet',worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

}
