<app-sidemenu></app-sidemenu>
<br>
<section class="contenido">
  <router-otlet></router-otlet>

</section>


<!-- <br><br><br><br> -->

<app-footer></app-footer>



