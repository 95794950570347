import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthAdminOperarioGuard implements CanActivate {
  private rolCliente=environment.IdRolCliente

  constructor(private router: Router,
              private storageService: StorageService) { }

  canActivate() {    
    if (this.storageService.isAuthenticated() && this.userAuthorized()) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page
    // this.router.navigate(['/login']);
    this.storageService.logout();
    window.open(window.location.origin+"/login", "_self")
    return false;
  }

  userAuthorized(){
    let loginUserData = this.storageService.getCurrentUser();

    switch (loginUserData.strRolId){
      case this.rolCliente:
        return false;

      default:
        return true;
    }
  }
  
}
