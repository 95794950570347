import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { StorageService } from 'src/app/services/storage.service';


@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  cookie:string;

  constructor(public thisDialogRef: MatDialogRef<MatDialog>,
    private storageService: StorageService) {
   }

  ngOnInit() {
  }

  public logout(): void{
    this.thisDialogRef.close('logout');
  }
  

}
