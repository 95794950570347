import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {FormControl} from '@angular/forms';
import { MatDialog } from '@angular/material';
import { InicioComponent } from '../inicio/inicio.component';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { StorageService } from 'src/app/services/storage.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {

  public loginUserData: User;
  showSpinner: boolean = false;
  miTrabajo:boolean=false;
  soyAdmin:boolean=false; 

  abierto:boolean=true;
  cerrado:boolean=false;
  hide = true;
  // miConfiguracion:any[]=[];

  admin:boolean=false;
  cliente:boolean=false;
  operario:boolean=false;

  noHayIdInstalacion:boolean=false;
  inicio:boolean=true;

  constructor(public dialog: MatDialog, private router:Router,private titleService: Title,
    private storageService: StorageService) { }

  ngOnInit() { 
    this.tokenExpires();

    this.loginUserData = this.storageService.getCurrentUser();
    this.definirMenu();
    // console.log(this.storageService.getCurrentSession())

    let newTitle:string;
    newTitle=this.storageService.getCurrentSession().strNombreTitulo;
    this.titleService.setTitle( newTitle );
  }

  tokenExpires(){
    // console.log(this.storageService.getCurrentSession().token[".expires"])
    var event = new Date(this.storageService.getCurrentSession().token[".expires"]);
    var jsonDate = event.toJSON();
    var today = new FormControl(new Date()).value.toJSON();
    if(jsonDate>today){
      // console.log(1)
    }else if(jsonDate<today){
      // console.log(2)
      setTimeout(() => {
        this.openWindow();
      }, 100); 
    }  
  }

  agregarItemHijo(){}

  openWindow(){
    let dialogRef = this.dialog.open(InicioComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result==="logout"){
        // this.logout();
        this.showSpinner = true;
        this.storageService.logout();
        // console.log(window.location.origin)
        
        setTimeout(()=>{
          this.showSpinner = false;
          window.open(window.location.origin+"/login", "_self")
          // this.router.navigate(["***"]);
        },1000)
        
      }
    });
  }

  public logout(): void{
    this.showSpinner = true;

    // setTimeout(() => {
    //   this.showSpinner = false;
      this.storageService.logout();
      // this.router.navigate(["/login"]);
      setTimeout(()=>{
        this.showSpinner = false;
        window.open(window.location.origin+"/login", "_self")
        // this.router.navigate(["***"]);
      },1000)

    // }, 500);  
  }


  definirMenu(){
  // console.log(this.storageService.getCurrentSession());
  //1. --> Administrador
  //2. --> Cliente
  //3. --> Operario
  
  switch(this.storageService.getCurrentSession().user.strRolId){
    case "1":
      return this.admin=true;
    
    case "2":
      return this.cliente=true;

    case "3":
    return this.operario=true;
  }

  }

  verHotel(){    
    
    this.showSpinner = true;

          setTimeout(() => {
            this.showSpinner = false;
            this.router.navigate(['/hotel'])
          }, 1000);  
  }


 openNav() {
  document.getElementById("miSidenav").style.width="250px";

  this.abierto=true;
  this.cerrado=false;
}
 closeNav() {
   //console.log('oo')
  document.getElementById("miSidenav").style.width="0";
  
  this.abierto=false;
  this.cerrado=true;
  
}

}
