<!-- 

<form class="login" [formGroup]="loginForm" (keydown)="keyDownFunction($event)">
  <input type="text" formControlName="username" placeholder="Usuario" [(ngModel)]="strUserName">
  <input formControlName="password" placeholder="Contraseña" [(ngModel)]="strPassword" [type]="hide ? 'password' : 'text'">
  <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
  <div class="errorMessage" *ngIf="showError">{{message}}</div>
  <button (click)="submitLogin()">Login</button>  
  <span class="recuperacion" *ngIf="sesionEnUso">
    <a (click)="recuperacionPassword()"> ¿Has olvidado la contraseña? </a>
  </span>
  <nav class="miSpinner" [style.display]="showSpinner ? 'block' : 'none'" >
    <mat-spinner ></mat-spinner>
  </nav>
</form>
 -->

 
<mat-card *ngIf="showForm">
  <form [formGroup]="loginForm">
    <!-- <fieldset> -->
      <!-- <legend> <img src="../../assets/logotus.png" alt="" class="miLogotus"> </legend> -->

      <section>
        <div class="col-3">
          <label style="font-size:11px; color:rgb(180, 180, 180)">Usuario</label>
          <input class="effect-2" formControlName="username" type="text" [(ngModel)]="strUserName" name="strUserName" placeholder="example@gmail.com">
          <span class="focus-border"></span>
        </div>
        <mat-hint class="formatError" style="color: red;" *ngIf="submitted && loginForm.controls.username.hasError('required')">*Campo necesario</mat-hint>
      </section>

      <section>
        <div class="col-3">
          <label style="font-size:11px; color:rgb(180, 180, 180)">Contraseña</label>
          <input class="effect-2" [type]="hide ? 'password' : 'text'" formControlName="password" [(ngModel)]="strPassword" name="strPassword" placeholder="Password123">
          <span class="focus-border"></span>
          <mat-icon matSuffix (click)="hide = !hide" class="iconFloat">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </div>
        <mat-hint class="formatError" style="color: red;" *ngIf="submitted && loginForm.controls.password.hasError('required')">*Campo necesario</mat-hint>
      </section>      

      <div>
        <span *ngIf="isLoginError" class="error-message">
          <mat-icon>no_encryption</mat-icon> Usuario o contraseña incorrectos
        </span>
      </div>

      <div>
        <button mat-raised-button (click)="submitLogin()" class="miBoton">Acceder</button>
      </div>
      <br><br><br>
      <!-- </fieldset> -->
   </form>

<!-- <br><br> 

  <div>
    <h2><i> ¿Eres un nuevo usuario? </i></h2>
    <br>
    <div>
      <button mat-raised-button (click)="createUserLogin()" class="miBoton2">Usuario nuevo</button>
    </div>
  </div>

  <br><br> -->

</mat-card>


<!-- <div class="form-structor">
	<div class="signup">
    <h2 class="form-title" id="signup">Regístrate</h2>
		<div class="form-holder">
			<input type="text" class="input" placeholder="Nombre" />
			<input type="email" class="input" placeholder="Email" />
			<input type="password" class="input" placeholder="Contraseña" />
		</div>
		<button class="submit-btn">Registrarse</button>
	</div>
	<div class="login slide-up" id="formLogin">
		<div class="center">
      <h2 class="form-title" id="login" (click)="showLogin()">Inicia sesión</h2>
			<div class="form-holder">
				<input type="email" class="input" placeholder="Email" />
				<input type="password" class="input" placeholder="Contraseña" />
			</div>
			<button class="submit-btn">Iniciar sesión</button>
		</div>
	</div>
</div> -->



<!-- <div class="container" id="container">
	<div class="form-container sign-up-container">
		<form action="#">
			<h1>Alta usuarios</h1>
			<input type="email" placeholder="Email" />
			<input type="password" placeholder="Contraseña" />
			<input type="password" placeholder="Repita contraseña" />
			<button>Registrarse</button>
		</form>
	</div>
	<div class="form-container sign-in-container">
		<form action="#">
			<h1>Inicia sesión</h1>
			<input type="email" placeholder="Email" />
			<input type="password" placeholder="Contraseña" />
			<button>Iniciar sesion</button>
		</form>
	</div>
	<div class="overlay-container">
		<div class="overlay">
			<div class="overlay-panel overlay-left">
				<h1>Entrar</h1>
				<p>Inicia sesion con tu correo electrónico</p>
				<button class="ghost" id="signIn" (click)="showloginform()">Inicia sesión</button>
			</div>
			<div class="overlay-panel overlay-right">
				<h1>Regístrate</h1>
				<p>Formulario de alta de usuarios</p>
				<button class="ghost" id="signUp" (click)="showRegisterform()">Registrarse</button>
			</div>
		</div>
	</div>
</div> -->


<div class="containerLoader" *ngIf="showSpinner">
  <div class="wrapper">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="shadow"></div>
    <div class="shadow"></div>
    <div class="shadow"></div>
  </div>
</div>

