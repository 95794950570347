import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CRespuestaService } from '../login/shared/CRespuesta.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { User } from 'src/app/models/user.model';
import { StorageService } from 'src/app/services/storage.service';
import { NotificacionComponent } from '../Ventanas emergentes/notificacion/notificacion.component';

@Component({
  selector: 'app-login-recuperacion',
  templateUrl: './login-recuperacion.component.html',
  styleUrls: ['./login-recuperacion.component.scss']
})
export class LoginRecuperacionComponent implements OnInit {

public loginForm: FormGroup;
  public submitted: Boolean = false;
  public emailError:Boolean=false;
  strUserName:string;

  public loginUserData:User=new User();
  
  constructor(private storageService: StorageService,private dialog: MatDialog,
    private router: Router,private formBuilder: FormBuilder,
    private CRespuestaService:CRespuestaService) { }

  ngOnInit() {
   
    this.loginForm  = this.formBuilder.group({
     
      username: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ]))
    });
  }

  public submitLogin() {
    this.submitted = true;
      if(this.loginForm.valid){
      //falta la funcion de recuperacion de contraseña
      this.CRespuestaService.recuperaPassword(this.strUserName).subscribe(datos=>{
        // console.log(datos)
        if(datos.booOK){
          let dialogRef = this.dialog.open(NotificacionComponent, {
            width: '600px',
            data:{
              texto:"Se ha iniciado el proceso de recuperación de contraseña.",
              texto2:"Recibirá un correo electrónico en la mayor brevedad posible.",
              boton:false,
              btnError:false,
              btnOK:true
            }
          });
        }else{
          let dialogRef = this.dialog.open(NotificacionComponent, {
            width: '600px',
            data:{
              texto:datos.strMensaje,
              boton:false,
              btnError:true,
              btnOK:false
            }
          });
        }
      })
      }else{
        this.emailError=true;      
      }
    }
}
