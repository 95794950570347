import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthAdminGuard } from './guards/authAdminGuard';
import { AuthGuard } from './guards/authGuard';
import { LoginComponent } from './components/login/login.component';
import { LoginRecuperacionComponent } from './components/login-recuperacion/login-recuperacion.component';
import { AuthAdminOperarioGuard } from './guards/auth-admin-operario.guard';
import { AuthClienteGuard } from './guards/auth-cliente.guard';
import { InicioComponent } from './components/inicio/inicio.component';
import { SidemenuComponent } from './components/sidemenu/sidemenu.component';
import { LayoutComponent } from './components/layout/layout.component';
// import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
// import { DetalleRecargaComponent } from './components/detalle-recarga/detalle-recarga.component';
// import { CreateUserComponent } from './components/create-user/create-user.component';
// import { PagoFinalizadoComponent } from './components/pago-finalizado/pago-finalizado.component';
// import { ConfirmacionRegistroComponent } from './components/confirmacion-registro/confirmacion-registro.component';
import { NotificacionComponent } from './components/Ventanas emergentes/notificacion/notificacion.component';
import { VentanaTipoTarjetaComponent } from './components/Ventanas emergentes/ventana-tipo-tarjeta/ventana-tipo-tarjeta.component';
import { DetalleOperarioComponent } from './components/Ventanas emergentes/detalle-operario/detalle-operario.component';
// import { CambiaContrasenaComponent } from './components/cambia-contrasena/cambia-contrasena.component';
// import { OperariosComponent } from './components/operarios/operarios.component';
// import { ConfiguracionComponent } from './components/configuracion/configuracion.component';
// import { TiposTarjetaComponent } from './components/tipos-tarjeta/tipos-tarjeta.component';
import { ListadoComponent } from './components/listado/listado.component';
import { ListTarjetasComponent } from './components/list-tarjetas/list-tarjetas.component';
// import { HistorialRecargasComponent } from './components/historial-recargas/historial-recargas.component';
// import { DetalleRecargasComponent } from './components/detalle-recargas/detalle-recargas.component';
import { DetalleClienteComponent } from './components/detalle-cliente/detalle-cliente.component';
import { ExplotacionesComponent } from './components/Ventanas emergentes/explotaciones/explotaciones.component';
import { RebanosComponent } from './components/Ventanas emergentes/rebanos/rebanos.component';
import { ListadoRebanosComponent } from './components/listado-rebanos/listado-rebanos.component';
import { ListadoEspeciesComponent } from './components/listado-especies/listado-especies.component';
import { ListadoRazasComponent } from './components/listado-razas/listado-razas.component';
// import { VentanaRecargasTarjetaComponent } from './components/ventana-recargas-tarjeta/ventana-recargas-tarjeta.component';
// import { DatosClienteComponent } from './components/datos-cliente/datos-cliente.component';
// import { InsertarMitarjetaComponent } from './components/insertar-mitarjeta/insertar-mitarjeta.component';
// import { TarjetasComponent } from './components/tarjetas/tarjetas.component';
// import { RecargarComponent } from './components/recargar/recargar.component';
// import { MisRecargasComponent } from './components/mis-recargas/mis-recargas.component';
// import { SetPasswordComponent } from './components/operarios/set-password/set-password.component';
// import { TiposTarjetaRecargasComponent } from './components/tipos-tarjeta-recargas/tipos-tarjeta-recargas.component';
// import { DetalleTipoTarjetaRecargaComponent } from './components/detalle-tipo-tarjeta-recarga/detalle-tipo-tarjeta-recarga.component';
// import { TotemsComponent } from './components/totems/totems.component';
// import { DetalleTotemComponent } from './components/detalle-totem/detalle-totem.component';
// import { DetalleTarjetaComponent } from './components/detalle-tarjeta/detalle-tarjeta.component';
// import { RecargasTotemComponent } from './components/recargas-totem/recargas-totem.component';


const routes: Routes = [

  /************ componentes que se pueden cargar sin loguearse ***********/
  { path: 'login', component: LoginComponent },
  // { path: 'toolbar', component: ToolbarComponent },
  { path: 'login-recuperacion', component: LoginRecuperacionComponent },
  // { path: 'create-user', component: CreateUserComponent },
  // { path: 'pago-finalizado', component: PagoFinalizadoComponent }, 
  // { path: 'confirmacion-registro', component: ConfirmacionRegistroComponent },
  { path: 'notificacion', component: NotificacionComponent },
  { path: 'ventana-tipo-tarjeta', component: VentanaTipoTarjetaComponent },
  { path: 'detalle-operario', component: DetalleOperarioComponent },
  // { path: 'recuperacion-contrasena', component: CambiaContrasenaComponent },
  // { path: 'detalle-tipo-tarjeta-recarga', component: DetalleTipoTarjetaRecargaComponent },
  // { path: 'detalle-totem', component: DetalleTotemComponent },

  /************************* Estos son los componentes visibles para el administrador ************************/

  // { path: 'operarios', component: OperariosComponent, canActivate: [ AuthAdminGuard ] },
  // { path: 'formulario-operador', component: AddOperarioComponent, canActivate: [ AuthAdminGuard ] },
  // { path: 'detalle-operador', component: UpdateOperarioComponent, canActivate: [ AuthAdminGuard ] },
  // { path: 'set-pass', component: SetPasswordComponent, canActivate: [ AuthAdminGuard ] },
  // { path: 'configuracion', component: ConfiguracionComponent, canActivate: [ AuthAdminGuard ] },
  // { path: 'tipos-tarjeta', component: TiposTarjetaComponent, canActivate: [ AuthAdminGuard ] },
  // { path: 'tipos-tarjeta-recarga', component: TiposTarjetaRecargasComponent, canActivate: [ AuthAdminGuard ] },
  // { path: 'totems', component: TotemsComponent, canActivate: [ AuthAdminGuard ] },

  /************************* Estos son los componentes visibles para el administrador y operador ************************/

  { path: 'listado-animales', component: ListadoComponent, canActivate: [ AuthAdminOperarioGuard ] },
  { path: 'explotaciones', component: ListTarjetasComponent, canActivate: [ AuthAdminOperarioGuard ] },
  // { path: 'editar-admintarjeta', component: EditarTarjetaAdminComponent, canActivate: [ AuthAdminOperarioGuard ] },
  // { path: 'historial-recargas', component: HistorialRecargasComponent, canActivate: [ AuthAdminOperarioGuard ] },
  // { path: 'detalle-recarga', component: DetalleRecargasComponent, canActivate: [ AuthAdminOperarioGuard ] },
  { path: 'detalle', component: DetalleClienteComponent, canActivate: [ AuthAdminOperarioGuard ] },
  { path: 'select-explotacion', component: ExplotacionesComponent, canActivate: [ AuthAdminOperarioGuard ] },
  { path: 'select-rebano', component: RebanosComponent, canActivate: [ AuthAdminOperarioGuard ] },
  { path: 'rebanos', component: ListadoRebanosComponent, canActivate: [ AuthAdminOperarioGuard ] },
  { path: 'especies', component: ListadoEspeciesComponent, canActivate: [ AuthAdminOperarioGuard ] },
  { path: 'razas', component: ListadoRazasComponent, canActivate: [ AuthAdminOperarioGuard ] },
  // { path: 'recargas-tarjeta', component: VentanaRecargasTarjetaComponent, canActivate: [ AuthAdminOperarioGuard ] },
  // { path: 'detalle-tarjeta', component: DetalleTarjetaComponent, canActivate: [ AuthAdminOperarioGuard ] },
  // { path: 'recargas-totem', component: RecargasTotemComponent, canActivate: [ AuthAdminOperarioGuard ] },

/************************* Estos son los componentes visibles para el cliente ************************/

  // { path: 'datos-cliente', component: DatosClienteComponent, canActivate: [ AuthClienteGuard ] },
  // { path: 'insertar-mitarjeta', component: InsertarMitarjetaComponent, canActivate: [ AuthClienteGuard ] },
  // { path: 'editar-mitarjeta', component: EditarMitarjetaComponent, canActivate: [ AuthClienteGuard ] },
  // { path: 'tarjetas', component: TarjetasComponent, canActivate: [ AuthClienteGuard ] },
  // { path: 'recargar', component: RecargarComponent, canActivate: [ AuthClienteGuard ] },  
  // { path: 'mis-recargas', component: MisRecargasComponent, canActivate: [ AuthClienteGuard ] },  

  /****************************************** Estos son los componentes comunes para los usuarios logueados ****************************************/

  // { path: 'dato-recarga', component: DetalleRecargaComponent, canActivate: [ AuthGuard ] },

  /****************************************** Estos son los componentes del empleado ****************************************/
    
  { path: 'login', component: LoginComponent },
  { path: 'inicio2', component: InicioComponent },
  // { path: 'ventana-error', component: VentanaErrorComponent },
  { path: 'sidemenu', component: SidemenuComponent },
  { path: 'layout', component: LayoutComponent },
  { path: 'login-recuperacion', component: LoginRecuperacionComponent },
  // { path: 'reset-password', component: ResetPasswordComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/login'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
