import { Component, OnInit,ViewChild, HostListener, ElementRef } from '@angular/core';
import {MatTableDataSource, MatSort, DateAdapter,MatPaginator, MatDialog, MatRadioChange, MatTabChangeEvent} from '@angular/material';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';
import {FormControl} from '@angular/forms';
import { DataSource } from '@angular/cdk/collections';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge } from 'rxjs';
import { DatePipe} from '@angular/common';
import { Tarjeta } from 'src/app/models/tarjeta';
import { AuxEstado } from 'src/app/models/aux-estado';
import { StorageService } from 'src/app/services/storage.service';
import { AdministradorService } from 'src/app/services/administrador.service';
import { NotificacionComponent } from '../Ventanas emergentes/notificacion/notificacion.component';
import { Explotacion } from 'src/app/models/ganaderia/explotacion';

@Component({
  selector: 'app-list-tarjetas',
  templateUrl: './list-tarjetas.component.html',
  styleUrls: ['./list-tarjetas.component.scss']
})
export class ListTarjetasComponent implements OnInit {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  showSpinner: boolean = true;
  data:Explotacion[]=[];
  dataTodo:Explotacion[]=[];
  displayedColumns = ['intId','strNombre','strCodigo','strActivo'];
  dataSource ;
  filtro:string;

  listFiltrada:Explotacion[]=[];

  dataExcel:datosExcelListado[]=[];
  teclaAlt:any;
  otraTecla:any;

  idInst:any;
  numRegistros:any;
  filtrosEstado:AuxEstado[]=[];
  dataFilterEst:Tarjeta[]=[];
  dataFilterVinc:Tarjeta[]=[];
  selectEstado:any;

  constructor(private storageService: StorageService,private miservicio:AdministradorService,public datepipe: DatePipe,
    private dateAdapter: DateAdapter<Date>,public dialog: MatDialog,private router: Router) {
    this.dateAdapter.setLocale('es');   
   }

  ngOnInit() {
    this.filtrosEstado.push({intId:1, strNombre:"Activos", strValor:"S", booChecked:true}, {intId:2, strNombre:"Inactivos", strValor:"N", booChecked:false}, {intId:3, strNombre:"Todos", strValor:"T", booChecked:false});
    this.selectEstado=1;
    this.listar();
  }

  // tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
  //   this.showSpinner=true;
  //   if(tabChangeEvent.index!=0){
  //     this.listar();
  //     this.filtro=null;
  //     this.selectEstado=1;
  //   }else{
  //     this.listar();
  //   }
  // }

  detalleTarjeta(e:Tarjeta){
    let extra: NavigationExtras = {
      queryParams: {
        'intId':e.intClienteId,
        'intTarjeta':e.intId,
      }
    }
    this.router.navigate(["detalle-tarjeta"],extra);
  }

  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) { 
    this.otraTecla=event.keyCode;  
    
    if(this.teclaAlt===78 && this.otraTecla===18){
      // this.nuevo();
    }else if(this.teclaAlt===88 && this.otraTecla===18){
      // this.exportAsXLSX();
    }else if(this.teclaAlt===66 && this.otraTecla===18){
      var myEl = document.getElementById('miFiltro');
      myEl.focus();
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    //alert(event.keyCode)
    this.teclaAlt=event.keyCode;    
  }

  public listar(){
    this.miservicio.getExplotaciones(0,'','','').subscribe(datos=>{
      this.showSpinner=false;
      // console.log(datos)
      if(datos.miRespuesta.booOK){
        this.listFiltrada=datos.miListExplotaciones; 
        this.dataTodo=datos.miListExplotaciones;
        this.data=datos.miListExplotaciones.filter(i=>i.strActivo.toLowerCase()==="S".toLowerCase());

        this.numRegistros=this.data.length;
        this.dataSource=new MatTableDataSource(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator=this.paginator;
        this.data=this.dataTodo;
      }
    })
  }

  radioChange(event: MatRadioChange) {

    switch(event.value){
      case "S":
        this.selectEstado=1;
        break;

      case "N":
        this.selectEstado=2;
        break;

      case "T":
        this.selectEstado=3;
      break;

    }

    this.buscar();
  }

  radioChange2(event: MatRadioChange) {
    switch(event.value){
      case "S":
        this.selectEstado=4;
        break;

      case "N":
        this.selectEstado=5;
        break;

      case "T":
        this.selectEstado=6;
      break;

    }

    this.buscar();
  }

  getColor(e) { 
    switch (e) {
      case 'S':
        // return '#00b300';
        return '#5cd65c';
      case 'N':
        // return '#ff3333';
        return '#ff6666';
    }
  }

  buscar(){  
    this.dataTodo=this.data;
    if(this.filtro!=null){
      this.data=this.data.filter(i=>i.strNombre.replace(/\s/g, "").toLowerCase().includes(this.filtro.replace(/\s/g, "").toLowerCase()) || 
                                 i.strCodigo.replace(/\s/g, "").toLowerCase().includes(this.filtro.replace(/\s/g, "").toLowerCase()));
      
    }

    if(this.selectEstado!=0){
      switch(this.selectEstado){
        case 1:
          this.data=this.data.filter(i=>i.strActivo.toLowerCase()==="S".toLowerCase());
          break;
  
        case 2:
          this.data=this.data.filter(i=>i.strActivo.toLowerCase()==="N".toLowerCase());
          break;
  
        case 3:
        break;

        // case 4:
        //   this.data=this.data.filter(i=>i.strVinculadoCliente.toLowerCase()==="S".toLowerCase());
        //   break;
  
        // case 5:
        //   this.data=this.data.filter(i=>i.strVinculadoCliente.toLowerCase()==="N".toLowerCase());
        //   break;

        //   case 6:
        // break;
      }
    }

    this.numRegistros=this.data.length;
    this.listFiltrada=this.data;
    this.dataSource=new MatTableDataSource(this.data);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator=this.paginator;
    this.data=this.dataTodo;
  }

  windowNotif(booBoton, booBtnError, btnOK, strMensaje, strParrafo2){
    let dialogRef = this.dialog.open(NotificacionComponent, {
      width: '600px',
      data:{
        texto:strMensaje,
        texto2:strParrafo2,
        boton:booBoton,
        btnError:booBtnError,
        btnOK:btnOK
      }
    });
    dialogRef.afterClosed().subscribe(result =>{
      // return result;
    })
  }

  // exportAsXLSX():void {
  //   this.listFiltrada.forEach(e => {
  //     this.dataExcel.push({
  //       Tarjeta:e.strNombre,
  //       Codigo:e.strCodigoTus,
  //       Nombre:e.strClienteNombre,
  //       Apellidos:e.strClienteApellidos,
  //       Dni:e.strClienteDni,
  //       Activo:e.strActivo,
  //       Fecha_registro:this.datepipe.transform(e.datFechaRegistro, 'dd-MM-yyyy')      
  //     })
  //   });
  //   this.miservicio.exportAsExcelFile(this.dataExcel, 'Listado de tarjetas');

  //   this.dataExcel.splice(0, this.dataExcel.length)
  // }

  deleteFilter(){
    //Refrescar la pagina
    window.location.reload();
  }

}

export interface datosExcelListado {
  Tarjeta:any;
  Codigo:any;
  Nombre:any;
  Apellidos:any;
  Dni:any;
  Activo:any;
  Fecha_registro:any;
}