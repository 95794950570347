<app-layout></app-layout>

<!-- <div class="cardTitulo">
  &nbsp; Detalle cliente
</div>
<br> -->

<mat-card class="contenidoPagina">
  <h3>Detalle <i>{{miAnimal.strNombre}}</i></h3>
</mat-card>

<mat-card class="contenidoPagina matCard2">

  <ul class="detalleCliente">
    <!-- <li class="table-row" *ngFor="let cliente of listaClientes">
        <div class="col col-1" data-label="Nombre"><span> {{cliente.strNombre}} </span></div>
        <div class="col col-2" data-label="Apellidos">{{cliente.strApellidos}}</div>
        <div class="col col-3" data-label="Dni">{{cliente.strDni}}</div>
        <div class="col col-3" data-label="Email">{{cliente.strEmail}}</div>
        <mat-slide-toggle class="col col-4" data-label="Estado"  [(ngModel)]="isChecked" name="isChecked" (click)="guardarAcitvo()">{{miEstado}}</mat-slide-toggle>    
    </li> -->

    <li>
      <!-- <label>Nombre</label>
      <span>{{miAnimal.strNombre}}</span> -->
      <mat-form-field class="example-full-width">
        <input matInput type="text" [(ngModel)]="miAnimal.strNombre" name="strNombre" placeholder="Nombre">
      </mat-form-field>
    </li>
    <li>
      <!-- <label>Apellidos</label>
      <span>{{miAnimal.strApellidos}}</span> -->
      <mat-form-field class="example-full-width">
        <input matInput type="text" [(ngModel)]="miAnimal.strCrotal" name="strCrotal" placeholder="Crotal">
      </mat-form-field>
    </li>
    <li>
      <!-- <label>D.N.I.</label>
      <span>{{miAnimal.strDni}}</span> -->
      <!-- <mat-form-field class="example-full-width">
        <mat-select placeholder="Sexo" (selectionChange)="selectSexo()" [(ngModel)]="miAnimal.intIdSexo" required>
            <mat-option *ngFor="let s of miListSexos" [value]="s.intId">
                {{s.strNombre}}
            </mat-option>
        </mat-select>
    </mat-form-field> -->
    <mat-form-field class="example-full-width">
      <mat-select [(ngModel)]="miAnimal.intIdSexo" name="intIdSexo" placeholder="Sexo" class="example-full-width">
        <mat-option name="intIdSexo" [value]="1">Macho</mat-option>
        <mat-option name="intIdSexo" [value]="2">Hembra</mat-option>
      </mat-select>
    </mat-form-field>
    </li>
    <li>
      <mat-form-field class="example-full-width">
      <mat-select [(ngModel)]="miAnimal.intIdEspecie" name="intIdEspecie" placeholder="Especie" (selectionChange)="selectEspecie()" class="example-full-width">
        <mat-option name="intIdEspecie" [value]="1">Vacuno</mat-option>
        <mat-option name="intIdEspecie" [value]="2">Oveja</mat-option>
      </mat-select>
    </mat-form-field>
    </li>
    <li>
      <mat-form-field class="example-full-width">
        <mat-select placeholder="Raza" [(ngModel)]="miAnimal.intIdRaza">
            <mat-option *ngFor="let x of miListRazas" [value]="x.intId">
                {{x.strNombre}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    </li>
    <li>
      <mat-form-field class="example-full-width">
      <mat-select [(ngModel)]="miAnimal.intIdEtapa" name="intIdEtapa" placeholder="Etapa">
        <mat-option name="intIdEtapa" [value]="1">Ternero</mat-option>
        <mat-option name="intIdEtapa" [value]="2">Destetado</mat-option>
        <mat-option name="intIdEtapa" [value]="3">Engorde</mat-option>
        <mat-option name="intIdEtapa" [value]="4">Adulto</mat-option>
      </mat-select>
    </mat-form-field>
    </li>
    <li>
      <mat-form-field class="example-full-width">
        <input matInput type="text" [(ngModel)]="miAnimal.strDib" name="strDib" placeholder="Dib">
      </mat-form-field>
    </li>
    <li>
      <mat-form-field class="example-full-width">
      <mat-select [(ngModel)]="miAnimal.intIdExplotacion" name="intIdExplotacion" placeholder="Explotacion">
        <mat-option name="intIdExplotacion" [value]="1">Explotacion 1</mat-option>
        <mat-option name="intIdExplotacion" [value]="2">Explotacion 2</mat-option>
      </mat-select>
    </mat-form-field>
    </li>
    <li>
      <mat-form-field class="example-full-width">
      <mat-select [(ngModel)]="miAnimal.intIdRebano" name="intIdRebano" placeholder="Rebaño">
        <mat-option name="intIdRebano" [value]="1">Rebaño 1</mat-option>
        <mat-option name="intIdRebano" [value]="2">Rebaño 2</mat-option>
        <mat-option name="intIdRebano" [value]="3">Rebaño 3</mat-option>
      </mat-select>
    </mat-form-field>
    </li>
    <li>
      <mat-form-field class="example-full-width">
        <mat-select placeholder="Madre" [(ngModel)]="miAnimal.intIdAnimalMadre">
            <mat-option *ngFor="let x of miListHembras" [value]="x.intId">
                {{x.strNombre}}
            </mat-option>
        </mat-select>
      </mat-form-field>
    </li>
    <li>
      <mat-form-field class="example-full-width">
        <mat-select placeholder="Padre" [(ngModel)]="miAnimal.intIdAnimalPadre">
            <mat-option *ngFor="let x of miListMachos" [value]="x.intId">
                {{x.strNombre}}
            </mat-option>
        </mat-select>
      </mat-form-field>
    </li>
    <li>
      <label>Estado</label>
      <mat-slide-toggle [(ngModel)]="isChecked" name="isChecked" (click)="guardarAcitvo()">{{miEstado}}</mat-slide-toggle>
    </li>
  </ul>
  <br>

  <div style="width: 100%; text-align: center;">
    <!-- <button mat-button matStepperPrevious class="cerrar" id="atras">Volver</button> -->
    <button mat-button  class="btn-confirm" id="siguiente" (click)="guardarDatos()">Guardar</button>
  </div>

  <!-- <br><br>
  <div class="table-row" style="text-align: center" *ngIf="sinDatos">
    <b>No hay tarjetas que mostrar</b> 
  </div> -->
  <!-- <br>
  <h4>Tarjetas</h4>
  
  <div>
    <label id="example-radio-group-label" style="font-size: 14px;">Estados </label>
    <br>
    <mat-radio-group
      aria-labelledby="example-radio-group-label"
      class="example-radio-group">
      <mat-radio-button class="example-radio-button" *ngFor="let est of filtrosEstado" [value]="est.strValor" [checked]="est.booChecked" (change)="radioChange($event)" style="font-size: 14px;">
        {{est.strNombre}}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <br>

  <mat-table #table [dataSource]="dataSource" matSort class="table-items tablaMaterial">    
    <ng-container matColumnDef="intId">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="minCellH"> Nº </mat-header-cell>
      <mat-cell *matCellDef="let objeto; let i = index" class="minCell">  
          {{i + 1}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="strNombre">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Descripción</mat-header-cell>
      <mat-cell *matCellDef="let tarjeta"> <div>{{tarjeta.strNombre}}</div> </mat-cell>
    </ng-container>

    <ng-container matColumnDef="strCodigoTus">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Número</mat-header-cell>
      <mat-cell *matCellDef="let tarjeta"><div>{{tarjeta.strCodigoTus}}</div></mat-cell>
    </ng-container>

    <ng-container matColumnDef="strActivo">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="minCell"></mat-header-cell>
      <mat-cell *matCellDef="let tarjeta" class="minCell">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <img src="../../assets/more_vert.png" width="23" height="23">
        </button>
        <mat-menu #menu="matMenu">
          <div class="with-icon" (click)="editTarjeta(tarjeta)">
            <mat-icon>visibility</mat-icon> &nbsp; Ver recargas
          </div>
          <div class="with-icon" (click)="cambiarEstadoTarjeta(tarjeta)">
            <mat-icon>warning</mat-icon> &nbsp; {{tarjeta.strActivo === 'S' ? 'Desactivar' : 'Activar'}}
          </div>
        </mat-menu>
      </mat-cell>
    </ng-container>
    
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let tarjeta; columns: displayedColumns;"></mat-row>
  </mat-table>


  <mat-paginator #paginator [pageSizeOptions]="[50, 100, 150]" ></mat-paginator> -->

  </mat-card>

  <div class="containerLoader" *ngIf="showSpinner">
    <div class="wrapper">
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="shadow"></div>
      <div class="shadow"></div>
      <div class="shadow"></div>
    </div>
  </div>