export class Token {

    public access_token?:string;
    public token_type?:string;
    public expires_in?:number;
    public userName?:string;
    public issued?:Date;
    public expires?:Date;

}
