import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/models/user.model';
import { CreateUser } from 'src/app/models/create-user';
import { Configuraciones } from 'src/app/models/configuraciones';
import { Session } from 'src/app/models/session.model';
import { Respuesta } from 'src/app/models/respuesta';
import { Config } from 'src/app/models/config';

@Injectable()
export class CRespuestaService {

  constructor(private http: HttpClient,private storageService: StorageService) {}

  cargarDatosIniciales(): Observable<Config>{    
    return this.http.get('assets/config.json');
  }

  // public url: string = environment.baseUrl;
  url: any = this.cargarDatosIniciales().subscribe(datos=> this.url=datos.url_rest);

sesion(usuario,strPassword): Observable<any>{    
  let dato = '{"strUsuario":"'+ usuario +'","strContrasena":"' + strPassword + '"}';
  let headers = new HttpHeaders().set('Content-Type','application/json');
   
  return this.http.post(this.url + '/api/Sesion', dato, {headers: headers});
}

token(mistrUsuario:string,mistrPassword:string): Observable<any>{    
  var data = "username=" + mistrUsuario + "&password=" + mistrPassword + "&grant_type=password";
  var reqHeader =new HttpHeaders().set('Content-Type','application/json');
  return this.http.post<any>(this.url + '/token' , data, { headers: reqHeader });
}

envialEmailRecuperacionContrasena(loginUserData:User,cookie):Observable<any>{
  var reqHeader =new HttpHeaders().set('Content-Type','application/json');
  let urlCompleta=this.url+'/api/establecimiento/forgotPassword?'+"strCookie="+cookie;
  let dato = '{"Email": "'+ loginUserData.strUserName +'"}'; 

  return this.http.post<any>(urlCompleta , dato, { headers: reqHeader });
}

changePasswordAdmin(strUserName,strPassword,ConfirmPassword,cookie,id):Observable<any>{
  var reqHeader =new HttpHeaders().set('Content-Type','application/json');
  let urlCompleta=this.url+'/api/establecimiento/SetPassword?'+"strCookie="+cookie+"&strId="+id;
  let dato = '{"strUserName": "'+ strUserName +'", "strPassword":"' + strPassword + '", "ConfirmPassword":"' + ConfirmPassword + '"}'; 

  return this.http.post<any>(urlCompleta , dato, { headers: reqHeader });
}

findCRespuesta(strUserName:string,strPassword:string): Observable<any>{
    
  var data = "username=" + strUserName + "&password=" + strPassword + "&grant_type=password";
  var reqHeader =new HttpHeaders().set('Content-Type','application/json');
  return this.http.post<any>(this.url + '/token' , data, { headers: reqHeader });
}

findSession(usuario,strPassword):Observable<any>{

  // var headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer ' + miToken });
  // var body = '{"strUsuarioNombre": "'+ miUserName +'"}';
  let dato = '{"strUsuario":"'+ usuario +'","strContrasena":"' + strPassword + '"}';
  let headers = new HttpHeaders().set('Content-Type','application/json');

  return this.http.post<any>(this.url + '/api/sesion', dato, { headers: headers })
}

addUser(newUser:CreateUser){
  let dato = '{"TipoUsuario": '+ newUser.intTipoUsuario +', "Email": "'+ newUser.strEmail +'", "Password":"'+ newUser.strPassword +'", "ConfirmPassword":"'+ newUser.strConfirmPassword +'", "strUsuarioNombre":"'+ newUser.strNombre +'", "strUsuarioApellidos":"'+ newUser.strApellidos +'", "strUsuarioDni":"'+ newUser.strDni +'", "strUsuarioFechaNacimiento":"'+ newUser.strFechaNacimiento +'"}';
  let json=dato;
  let params = json; 
  let headers = new HttpHeaders().set('Content-Type','application/json');     
  return this.http.post(this.url + '/api/account/register', params, {headers: headers});
}

sendEmail(id){

  let params = 'strCookieId='+id+'';
  let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');     
  return this.http.put(this.url + '/api/sesion?'+ params, {headers: headers});

}

//recuperaDatosIniciales
getDatosInicialesApp(url):Observable<Configuraciones>{
  var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  let urlCompleta=url+'/api/configuracion?';

  return this.http.get<Configuraciones>(urlCompleta,  { headers: headers });
}

getConfiguraciones(miOtroToken):Observable<Configuraciones>{
  var headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer ' + miOtroToken });
  let urlCompleta=this.url+'/api/configuracion?';

  return this.http.get<Configuraciones>(urlCompleta,  { headers: headers });
}


recuperaPassword(strEmail):Observable<Respuesta>{
  let dato='strEmail='+strEmail;
  let urlCompleta=this.url+'/Cliente/ForgotPassword?'+dato;
  let httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'  })
  };

  return this.http.get<Respuesta>(urlCompleta,  httpOptions);
}

recuperaUser(strCookieId):Observable<Respuesta>{
  let dato='strCookieId='+strCookieId;
  let urlCompleta=this.url+'/api/Sesion/setPassword?'+dato;
  let httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'  })
  };

  return this.http.put<Respuesta>(urlCompleta,  httpOptions);
}

putPassword(UserId, NewPassword, ConfirmPassword):Observable<any>{
  var body = '{"UserId":"'+ UserId +'", "NewPassword":"'+ NewPassword +'",  "ConfirmPassword":"'+ ConfirmPassword +'"}';

  let httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'  })
  };


  return this.http.post<any>(this.url + '/api/Account/SetPassword', body, httpOptions)
}

}
