import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource, MatRadioChange } from '@angular/material';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { DataSource } from '@angular/cdk/table';
import { Observable, of as observableOf, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificacionComponent } from '../Ventanas emergentes/notificacion/notificacion.component';
import { Cliente } from 'src/app/models/cliente';
import { Tarjeta } from 'src/app/models/tarjeta';
import { AuxEstado } from 'src/app/models/aux-estado';
import { StorageService } from 'src/app/services/storage.service';
import { AdministradorService } from 'src/app/services/administrador.service';
import { Animal } from 'src/app/models/ganaderia/animal';
import { Raza } from 'src/app/models/ganaderia/raza';

@Component({
  selector: 'app-detalle-cliente',
  templateUrl: './detalle-cliente.component.html',
  styleUrls: ['./detalle-cliente.component.scss']
})
export class DetalleClienteComponent implements OnInit {

  miAnimal:Animal=new Animal();
  intId:number;
  // intTarjetaId:number;
  isChecked:boolean;
  miEstado:string;

  isCheckedTarjeta:boolean;
  miEstadoTarjeta:string;
  miEstado2:string;
  sinDatos:boolean=false;

  // @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  // @ViewChild(MatSort, {static: false}) sort: MatSort;

  // data:Tarjeta[]=[];
  // dataTodo:Tarjeta[]=[];
  // displayedColumns = ['intId','strNombre','strCodigoTus','strActivo'];
  // dataSource;
  showSpinner=false;
  filtrosEstado:AuxEstado[]=[];
  numRegistros:any;
  miListRazas:Raza[]=[];

  miListHembras:Animal[]=[];
  miListMachos:Animal[]=[];

  constructor(private storageService: StorageService, private miservicio:AdministradorService,
    public dialog: MatDialog,
    private route: ActivatedRoute,private router: Router,) {
      this.route.queryParams.subscribe(params => {
        this.intId=params["intId"];
        // this.intTarjetaId = params["intTarjeta"];
        // this.miCliente.strUserIdActualizacion = params["strUserIdActualizacion"];
      });
      // this.miCliente.intId = parseInt(this.loginUserData.strEntidadId)
     }

  ngOnInit() {
    this.filtrosEstado.push({intId:1, strNombre:"Activos", strValor:"S", booChecked:true}, {intId:2, strNombre:"Inactivos", strValor:"N", booChecked:false}, {intId:3, strNombre:"Todos", strValor:"T", booChecked:false});
    this.showSpinner=true;
    this.listarAnimales();
    // this.paginator._intl.itemsPerPageLabel = 'Elementos por pagina';
    // this.loginUserData = this.storageService.getCurrentUser();
    // console.log(this.intClienteId)
    if(this.intId!=0){
      this.listarCliente();
    }else{
      this.showSpinner=false;
    }
  }

  selectEspecie(){
    this.showSpinner=true;
    this.getRazas(this.miAnimal.intIdEspecie);
  }

  getRazas(idEspecie){
    this.miservicio.getRazas(0,idEspecie,'','S').subscribe(datos=>{
      // console.log(datos)
      this.showSpinner=false;
      this.miListRazas=datos.miListRazas;
    })
  }

  listarAnimales(){
    this.miservicio.getAnimales(0,'',0,0,0,'','','',0,'',0,0,0,0,'').subscribe(datos => {
      this.miListHembras=datos.miListAnimales.filter(i=>i.intIdSexo===2);
      this.miListMachos=datos.miListAnimales.filter(i=>i.intIdSexo===1);
    })
  }

  listarCliente(){

    this.miservicio.getAnimales(this.intId,'',0,0,0,'','','',0,'',0,0,0,0,'').subscribe(datos => {
      // console.log(datos)
      if(datos.miRespuesta.booOK){
        this.showSpinner=false;
        this.miAnimal = datos.miListAnimales[0]; 

        if(this.miAnimal.strActivo==='S'){
          this.isChecked=true;
          return this.miEstado='Activo';
        }else{
          this.isChecked=false;
          return this.miEstado='Inactivo';
        }
      }
  });

    /*this.miservicio.findCliente(parseInt(this.loginUserData.strEntidadId)).subscribe(datos=>{
      console.log(datos)
      this.listaClientes=datos as Cliente[];
      
      if(this.listaClientes[0].strActivo==='S'){
        this.isChecked=true;
        return this.miEstado='Activo';
      }else{
        this.isChecked=false;
        return this.miEstado='Inactivo';
      }
    })*/
  }

  guardarDatos(){
    this.showSpinner=true;
    this.miservicio.PutPostAnimal(this.miAnimal,this.intId).subscribe(datos=>{
      // console.log(datos)
      this.showSpinner=false;
      if(datos.miRespuesta.booOK){
        this.miAnimal = datos.miListAnimales[0]; 
        this.windowNotif(false,false,true,"Se han guardado los datos correctamente",'');
      }
      else{
        this.windowNotif(false,true,false,"Se ha producido un error",'');
      }
    })
  }

//   radioChange(event: MatRadioChange) {
//     this.dataTodo=this.data;

//     switch(event.value){
//       case "S":
//         this.data=this.data.filter(i=>i.strActivo.toLowerCase()==="S".toLowerCase());
//         this.numRegistros=this.data.length;
//         break;

//       case "N":
//         this.data=this.data.filter(i=>i.strActivo.toLowerCase()==="N".toLowerCase());
//         this.numRegistros=this.data.length;
//         break;

//         case "T":
//         // this.data=this.data.filter(i=>i.strActivo.toLowerCase()==="N".toLowerCase());
//         this.numRegistros=this.data.length;
//         break;

//     }
//     // this.listFiltrada=this.data;
//     // this.dataSource = new MyTableDataSource(this.paginator, this.sort);
//     this.dataSource=new MatTableDataSource(this.data);
//     this.dataSource.sort = this.sort;
//     this.dataSource.paginator=this.paginator;

//     this.data=this.dataTodo;
// }

  guardarAcitvo(){
    // this.showSpinner=true;
    // let estadoSalvar:string;
    if(this.isChecked===true){
      this.miAnimal.strActivo='N';
       this.miEstado='Inactivo';
    }else{
      this.miAnimal.strActivo="S";
       this.miEstado='Activo';
    }
    
    // this.miservicio.putActivo(this.storageService.getCurrentUser().strUserId,estadoSalvar,this.intId).subscribe(datos=>{
    //   // console.log(datos)
    //   this.showSpinner=false;
    //   if(datos.miRespuesta.booOK){

    //   }else{
    //     //Error
    //     this.windowNotif(false,true,false,"Se ha producido un eror.","Por favor inténtelo mas tarde.");
    //   }
    // })
  }
  
  // listarTarjeta(intTarjeta,vinculadoACliente){
  //   // this.dataSource = new MyTableDataSource(this.paginator, this.sort);
  //   this.miservicio.findTarjeta(this.intId,intTarjeta,vinculadoACliente).subscribe(datos => { 
  //     // console.log(datos)
  //   //this.miservicio.findTarjeta(parseInt(this.loginUserData.strEntidadId)).subscribe((datos:any)=>{      
  //     this.showSpinner=false;
  //     if(datos.miRespuesta.booOK){
  //       if(intTarjeta!=0){
  //         this.filtrosEstado.splice(0,this.filtrosEstado.length);
  //       }
  //       else{
  //         this.data=this.data.filter(i=>i.strActivo.toLowerCase()==="S".toLowerCase());
  //       }
  //       this.data=datos.miListTarjeta
  //       this.dataTodo=this.data;
        
  //       this.numRegistros=this.data.length;
  //       // this.listFiltrada=this.data;
  //       // this.dataSource = new MyTableDataSource(this.paginator, this.sort);
  //       this.dataSource=new MatTableDataSource(this.data);
  //       this.dataSource.sort = this.sort;
  //       this.dataSource.paginator=this.paginator;
  //       this.data=this.dataTodo;
        
  //     }else{
  //       this.windowNotif(false,true,false,"Se ha producido un eror al cargar el listado de tarjetas.","Por favor inténtelo mas tarde.");
  //     }
  //   })
  // }

  editTarjeta(tarjeta:Tarjeta){
    let extra: NavigationExtras = {
      queryParams: {
        // 'strNombre':tarjeta.strNombre,
        'intId':tarjeta.intId,
        // 'intClienteId':tarjeta.intClienteId,
      }
    }
    this.router.navigate(["recargas-tarjeta"],extra);
  }

  cambiarEstadoTarjeta(t){
    this.showSpinner=true;
    // console.log(t.strActivo)
    t.strUserIdActualizacion=this.storageService.getCurrentUser().strUserId;
    switch(t.strActivo){
      case "S":
        t.strActivo="N"
        break;

      case "N":
        t.strActivo="S"
        break;
    }

    // console.log(t.strActivo)
    // setTimeout(()=>{
    //   this.miservicio.putTarjeta(t, this.miAnimal.intId, "S").subscribe(datos=>{
    //     // console.log(datos)
    //     this.filtrosEstado.splice(0, this.filtrosEstado.length);
    //     this.filtrosEstado.push({intId:1, strNombre:"Activos", strValor:"S", booChecked:true}, {intId:2, strNombre:"Inactivos", strValor:"N", booChecked:false}, {intId:3, strNombre:"Todos", strValor:"T", booChecked:false});
    //     this.showSpinner=false;
    //     if(datos.miRespuesta.booOK){
    //       this.data=datos.miListTarjeta;
    //       this.dataTodo=this.data;
    //       this.data=this.data.filter(i=>i.strActivo.toLowerCase()==="S".toLowerCase());
    //       this.numRegistros=this.data.length;
    //       // this.dataSource = new MyTableDataSource(this.paginator, this.sort);
    //       this.dataSource=new MatTableDataSource(this.data);
    //       this.dataSource.sort = this.sort;
    //       this.dataSource.paginator=this.paginator;
    //       this.data=this.dataTodo;
    //     }else{
    //       this.windowNotif(false,true,false,datos.miRespuesta.strMensaje,"");
    //     }
    //   })
    // },100)
  }

  getColor(strActivo) { 
    switch (strActivo) {
      case 'N':
      this.miEstado2='Inactivo';
        return '#ff3333';
      case 'S':
      this.miEstado2='Activo';
        return '#00b300';
    }
  }

  windowNotif(booBoton, booBtnError, btnOK, strMensaje, strParrafo2){
    let dialogRef = this.dialog.open(NotificacionComponent, {
      width: '600px',
      data:{
        texto:strMensaje,
        texto2:strParrafo2,
        boton:booBoton,
        btnError:booBtnError,
        btnOK:btnOK
      }
    });
    dialogRef.afterClosed().subscribe(result =>{
      return result;
    })
  }
}


// export class MyTableDataSource extends DataSource<Tarjeta> {
//   data:Tarjeta[]=[];
//   miCliente:Cliente=new Cliente();
 
//    constructor(private paginator: MatPaginator, private sort: MatSort) {
//      super();
//    }
 
//    connect(): Observable<Tarjeta[]> {
//      const dataMutations = [
//        observableOf(this.data),
//        this.paginator.page,
//        this.sort.sortChange
//      ];
 
//      this.paginator.length = this.data.length;
 
//      return merge(...dataMutations).pipe(map(() => {
//        return this.getPagedData(this.getSortedData([...this.data]));
//      }));
//    }
 
//    disconnect() {}
 
//    private getPagedData(data: Tarjeta[]) {
//      const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
//      return data.splice(startIndex, this.paginator.pageSize);
//    }
 
 
//    private getSortedData(data: Tarjeta[]) {
//      if (!this.sort.active || this.sort.direction === '') {
//        return data;
//      }
 
//      return data.sort((a, b) => {
//        const isAsc = this.sort.direction === 'asc';
//        switch (this.sort.active) {
//          case 'strNombre': return compare(a.strNombre, b.strNombre, isAsc);
//          case 'strCodigoTus': return compare(+a.strCodigoTus, +b.strCodigoTus, isAsc);
//          default: return 0;
//        }
//      });
//    }
//  }
 
//  function compare(a, b, isAsc) {
//    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
//  }