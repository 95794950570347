import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  strTextoFooter:string;

  constructor(private storageService: StorageService,private titleService: Title) {
  }

  ngOnInit() {
    this.storageService.status.subscribe((val) => {
      if(this.storageService.isAuthenticated()){
        if(val!=undefined){
          this.strTextoFooter=val;
        }else{
          this.strTextoFooter=this.storageService.getCurrentSession().strNombre
        }
      }
  });
  }



}
