<section>
  <img src="../../../assets/icon-ok.png" *ngIf="muestraOk">
  <img src="../../../assets/error.png" *ngIf="muestraError">
</section>
<br>
<section>
  {{data.texto}}
</section>
<section>
  {{data.texto2}}
</section>
<br>
<section>
  <div class="agregar">
    <!-- <input type="button" value="Aceptar" (click)="pulsa()" class="guardar"> -->
    <button mat-raised-button (click)="pulsa()" class="btn-confirm">Aceptar</button>
    <!-- <input type="button" value="Cancelar" (click)="pulsa2()" class="cerrar" *ngIf="dosAcciones"> -->
    <button mat-raised-button (click)="pulsa2()" class="btn-cancel" *ngIf="dosAcciones">Cancelar</button>
  </div>
</section>