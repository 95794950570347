import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DatePipe } from '@angular/common';
import { Operador } from 'src/app/models/operador';
import { AdministradorService } from 'src/app/services/administrador.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-detalle-operario',
  templateUrl: './detalle-operario.component.html',
  styleUrls: ['./detalle-operario.component.scss']
})
export class DetalleOperarioComponent implements OnInit {

  formOperario: FormGroup;

  mensajeError:string;
  showSpinner=false;
  existError=false;

  submitted=false;
  miOperario:Operador=new Operador();

  strTituloFormulario:string;
  miFechaNueva:any;

  btnInsert=false;
  btnEdit=false;
  isNew=false;
  isEdit=false;

  constructor(private thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) private data: any,private _formBuilder: FormBuilder,public datepipe: DatePipe,
              private miservcio:AdministradorService, private storageService:StorageService) { }

  ngOnInit() {
    // console.log(this.data)
    
    if(this.data!=null){
      //Edicion
      this.isNew=false;
      this.isEdit=true;
      this.formOperario = this._formBuilder.group({
        nombre: [Validators.required],
        apellidos: [Validators.required],
        dni: [Validators.required],
        email: new FormControl('', Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
          //Validators.maxLength(5)
        ])),
        tel1:[''],
        activo:['']
      })

      this.getOperarios();
      this.btnEdit=true;
    }
    else{
      //Insercion
      this.isNew=true;
      this.isEdit=false;
      this.formOperario = this._formBuilder.group({
        nombre: [Validators.required],
        apellidos: [Validators.required],
        dni: [Validators.required],
        email: new FormControl('', Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ])),
        tel1:[''],
        // activo:[''],
        password: new FormControl('', Validators.compose([
        Validators.required,
        //Contraseña: minimo 1 mayuscula, 1 minuscula, 1 numero y 1 caracter especial
        Validators.pattern('(?=.*[a-zA-Z0-9])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{5,}'),
      ])),
        confirmaPass: new FormControl('', Validators.compose([
        Validators.required,
        //Contraseña: minimo 1 mayuscula, 1 minuscula, 1 numero y 1 caracter especial
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{5,}'),
      ])),
      })

      this.btnInsert=true;
      this.strTituloFormulario="Nuevo operario";
      this.miOperario.strActivo="N";
    }

    
  }

  changeAct(e){
    // console.log(e)
    if(e.checked){
      this.miOperario.strActivo="S";
    }
    else{
      this.miOperario.strActivo="N";
    }
  }

  getOperarios(){
    this.miservcio.getOperarios(this.data.intId).subscribe(datos=>{
      if(datos.miRespuesta.booOK){
        this.miOperario=datos.miListOperario[0];
      }
    })
  }

  // addEvent( event: MatDatepickerInputEvent<Date>) {
  //   this.miFechaNueva=this.datepipe.transform(event.value, 'yyyy-MM-dd');
  // }

  btnInsertar(){
    this.miOperario.strUserIdActualizacion=this.storageService.getCurrentUser().strUserId;
    this.miOperario=this.removeNulls(this.miOperario);

    this.submitted=true;
    if(this.formOperario.valid){

      if(this.miOperario.strPassword===this.miOperario.strConfirmPassword){
        this.showSpinner=true;
        this.miservcio.addOperador(this.miOperario).subscribe((datos:any)=>{
  
          if(datos.booOk){
            this.existError=false;
            //Recupero los operarios
            this.miservcio.getOperarios(0).subscribe(datos=>{
              this.showSpinner=false;
              if(datos.miRespuesta.booOK){
                this.thisDialogRef.close(datos.miListOperario)
              }
            })
          }else{
            this.showSpinner=false;
            this.existError=true;
            this.mensajeError=datos.strMensaje;
          }
        })
      }
      else{
        this.existError=true;
        this.mensajeError="La contraseña no coincide";
      }

      
    }
    
  }

  removeNulls(obj: any): any {
    if (obj === undefined) {
        return "";
    }
    if (typeof obj === 'object') {
        for (let key in obj) {
            obj[key] = this.removeNulls(obj[key]);
        }
    }
    return obj;
}

  btnEditar(){
    this.miOperario.strUserIdActualizacion=this.storageService.getCurrentUser().strUserId;
    // console.log(this.miOperario)
    this.submitted=true;
    if(this.formOperario.valid){
      this.showSpinner=true;
      this.miservcio.putOperario(this.miOperario).subscribe(datos=>{
        // console.log(datos)
        this.showSpinner=false;
        if(datos.miRespuesta.booOK){
          this.existError=false;
          this.thisDialogRef.close(datos.miListOperario)
        }else{
          this.existError=true;
          this.mensajeError=datos.miRespuesta.strMensaje;
        }
      })
    }
  }

  cancelar(){
    this.thisDialogRef.close();
  }

}
