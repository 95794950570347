import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CRespuestaService } from './components/login/shared/CRespuesta.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'AppFront';

  public constructor(private miservicio: CRespuestaService ) { }
 
  ngOnInit() {
      // this.recuperaConfiguracion();
  }

  cargarIni(){
    
  }

  recuperaConfiguracion(){

    this.miservicio.cargarDatosIniciales().subscribe(datosIni=>{
      // console.log(datosIni)
      // this.miservicio.getDatosInicialesApp(datosIni.url_rest).subscribe(datos=>{
      //   // console.log(datos)
      //   if (datos[0].strUsaSsl === 'S') {
      //     if (location.protocol === 'http:'){
      //       window.location.href = location.href.replace('http', 'https');
      //     }
      //   }
      // })
    })

    
  }

}
