import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { StorageService } from './storage.service';
import { ClienteResult } from '../models/cliente-result';
import { Cliente } from '../models/cliente';
import { TarjetaResult } from '../models/tarjeta-result';
import { Tarjeta } from '../models/tarjeta';
import { PasarelaOutputResult } from '../models/pasarela-output-result';
import { Pasarela } from '../models/pasarela';
import { RecargaResult } from '../models/recarga-result';
import { Configuraciones } from '../models/configuraciones';
import { TipoTarjetaRecargaResult } from '../models/tipo-tarjeta-recarga-result';
import { Respuesta } from '../models/respuesta';
import { TituloTarifaResult } from '../models/titulo-tarifa-result';
import { TipoTarjetaResult } from '../models/tipo-tarjeta-result';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  constructor(private http: HttpClient, private storageService: StorageService ) {}

  // public url: string = environment.baseUrl+'/api/';
  url=this.storageService.getCurrentUrlRest()+'/api/';

  private token=this.storageService.getCurrentToken();
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer ' + this.token  })
  };

  getTiposTarjetas(intId,strTipoExt,strNombre,strActivo):Observable<TipoTarjetaResult>{
    let dato='intId='+intId+'&strTipoExt='+strTipoExt+'&strNombre='+strNombre+'&strActivo='+strActivo;
    let urlCompleta=this.url+'TipoTarjeta?'+dato;
 
    return this.http.get<TipoTarjetaResult>(urlCompleta,  this.httpOptions);
  }

  //recuperaUnicamenteElNomreDeLaApp
  getNombreApp():Observable<Configuraciones>{
    var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let urlCompleta=this.url+'configuracion?';

    return this.http.get<Configuraciones>(urlCompleta,  { headers: headers });
  }

  getCliente(strEntidadId:string):Observable<ClienteResult>{
    let dato='intClienteId='+strEntidadId;
    let urlCompleta=this.url+'cliente?'+dato;
 
    return this.http.get<ClienteResult>(urlCompleta,  this.httpOptions);
  }

  putCliente(cliente:Cliente):Observable<ClienteResult>{
    // var body = '{"intId": '+ cliente.intId +', "strNombre":"'+ cliente.strNombre +'", "strApellidos":"'+ cliente.strApellidos +'", "strDni":"'+ cliente.strDni +'",  "strTelefono1":"'+ cliente.strTelefono1 +'", "strTelefono2":"'+ cliente.strTelefono2 +'",  "strEmail":"'+ cliente.strEmail +'", "strDireccion":"'+ cliente.strDireccion +'",  "strCp":"'+ cliente.strCp +'", "strPoblacion":"'+ cliente.strPoblacion +'", "strProvincia":"'+ cliente.strProvincia +'", "strPais":"'+ cliente.strPais +'", "strActivo":"'+ cliente.strActivo +'", "strUserIdActualizacion":"'+ idActualizacion +'", "strPin":"'+ cliente.strPin +'"}';

    // cliente.strUserIdActualizacion=idActualizacion;
    var body = JSON.stringify(cliente);

    return this.http.put<ClienteResult>(this.url + 'cliente', body, this.httpOptions)
  }

  getTarjetasCliente(intId,intTarjetaId,strVinculadoACliente):Observable<TarjetaResult>{
    let dato='intClienteId='+intId+'&intTarjetaId='+intTarjetaId+'&strVinculadoACliente='+strVinculadoACliente;
    let urlCompleta=this.url+'tarjeta?'+dato;
 
    return this.http.get<TarjetaResult>(urlCompleta,  this.httpOptions);
  }

  getMiUnicaTarjeta(strEntidadId,data):Observable<Tarjeta[]>{
    /****** Paso un cero para que me devuelva todas las tarjetas de un cliente *******/
    let dato='intClienteId=0&intTarjetaId='+data;
    let urlCompleta=this.url+'tarjeta?'+dato;
 
    return this.http.get<Tarjeta[]>(urlCompleta,  this.httpOptions);
  }

  postMiTarjeta(tarjeta:Tarjeta, intClienteId, strVinculadoACliente):Observable<TarjetaResult>{
    let dato = 'intClienteId='+intClienteId+'&strVinculadoACliente='+strVinculadoACliente;
    let urlCompleta = this.url + 'tarjeta?' + dato;
    // var body = '{"strNombre": "'+ tarjeta.strNombre +'", "strCodigoTus":"'+ tarjeta.strCodigoTus +'", "strActivo":"S", "strUserIdActualizacion":"'+ strUserIdActualizacion +'", "intClienteId":'+ intClienteId +'}';
    var body=JSON.stringify(tarjeta);
    
    return this.http.post<TarjetaResult>(urlCompleta, body, this.httpOptions)    
  }

  putMiTarjeta(tarjeta:Tarjeta, intClienteId, strVinculadoACliente):Observable<TarjetaResult>{
    let dato = 'intClienteId='+intClienteId+'&strVinculadoACliente='+strVinculadoACliente;
    let urlCompleta = this.url + 'tarjeta?' + dato;
    // var body = '{"strNombre": "'+ tarjeta.strNombre +'", "strCodigoTus":"'+ tarjeta.strCodigoTus +'", "strActivo":"S", "strUserIdActualizacion":"'+ strUserIdActualizacion +'", "intClienteId":'+ intClienteId +'}';
    var body=JSON.stringify(tarjeta);
    
    return this.http.put<TarjetaResult>(urlCompleta, body, this.httpOptions)    
  }

  putActivo(tarjeta,estadoNuevo,strUserId):Observable<Tarjeta>{
    var body = '{"intId": '+ tarjeta.intId +',"strNombre":"'+ tarjeta.strNombre +'", "strActivo":"'+ estadoNuevo +'", "strUserIdActualizacion":"'+ strUserId +'"}';
    
    return this.http.put<Tarjeta>(this.url + 'tarjeta', body, this.httpOptions)
  }

  postRecarga(miRecarga):Observable<PasarelaOutputResult>{
    // var body = '{"intClienteId": '+ strEntidadId +',"intTarjetaId": '+ selectObj +',"decImporte": '+ recarga +'}';
    var body=JSON.stringify(miRecarga);
   
    return this.http.post<PasarelaOutputResult>(this.url + 'recarga', body, this.httpOptions)
  }

 postPasarela(importe,miTransaccion):Observable<Pasarela>{
    var body = '{"strImporte": '+ importe +',"strTransaccionId":'+ miTransaccion +'}';
    
    return this.http.post<Pasarela>(this.url + 'pasarela', body, this.httpOptions)
  }

  getMisRecargas(intClienteId,intTarjetaId,intRecargaId,strCerrada,strVinculadoTarjeta,strFechaDesde,strFechaHasta):Observable<RecargaResult>{
    let dato='intClienteId='+ intClienteId +'&intTarjetaId='+intTarjetaId+'&intRecargaId='+intRecargaId+'&strCerrada='+strCerrada;
    dato+='&strVinculadoTarjeta='+strVinculadoTarjeta+'&strFechaDesde='+strFechaDesde+'&strFechaHasta='+strFechaHasta;
    let urlCompleta=this.url+'recarga?'+dato;
 
    return this.http.get<RecargaResult>(urlCompleta,  this.httpOptions);
  }

  putPassword(newUser:any, miEmail):Observable<any>{
    var body = '{"Email":"'+ miEmail +'", "OldPassword":"'+ newUser.OldPassword +'", "NewPassword":"'+ newUser.NewPassword +'",  "ConfirmPassword":"'+ newUser.ConfirmPassword +'"}';

    return this.http.post<any>(this.url + 'Account/ChangePassword', body, this.httpOptions)
  }

  //tipos de recarga
  getTiposTarjetasRecargas(intId,intImporte,strActivo):Observable<TipoTarjetaRecargaResult>{
    let dato='intId='+intId+'&intImporte='+intImporte+'&strActivo='+strActivo;
    let urlCompleta=this.url+'TipoTarjetaRecarga?'+dato;
 
    return this.http.get<TipoTarjetaRecargaResult>(urlCompleta,  this.httpOptions);
  }

  //PROCEDIMIENTOS
  compruebaTarjetaRecarga(strCodigoTUS):Observable<TituloTarifaResult>{
    let dato='strCodigoTUS='+strCodigoTUS;
    let urlCompleta=this.url+'Procedimiento/CompruebaTarjetaRecarga?'+dato;
 
    return this.http.get<TituloTarifaResult>(urlCompleta,  this.httpOptions);
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    //console.log('worksheet',worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
}
