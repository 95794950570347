<!-- <mat-card>
  
    <h2 class="titulo">Recuperación de contraseña</h2>
    <br><br>
    <form [formGroup]="loginForm">
  <mat-form-field class="example-full-width">

    <input matInput formControlName="username" [(ngModel)]="loginUserData.strUserName" name="strUsername" placeholder="Usuario" required>
    <mat-hint class="formatError" style="color: red;" *ngIf="submitted && loginForm.controls.username.hasError('required')">*Campo necesario</mat-hint>
    <mat-hint class="formatError" style="color: red;" *ngIf="submitted && loginForm.controls.username.hasError('pattern')">Introduzca un email válido</mat-hint>
  </mat-form-field>
</form>
<br><br>
  <button mat-raised-button (click)="submitLogin()" class="SubButton">Continuar</button>

  <nav class="miSpinner">
      <mat-spinner [style.display]="showSpinner ? 'block' : 'none'" ></mat-spinner>
  </nav>


  <div style="color:#ff3333;" class="mensaje" *ngIf="muestraError"><p style=" width: 100%; text-align: center;">{{mensaje}}</p></div>
  
</mat-card> -->

<mat-card>

  <form [formGroup]="loginForm">
    <fieldset>
      <legend> <img src="../../assets/logotus.png" alt=""> </legend>
  
      <section>
        <div class="col-3">
          <input class="effect-2" type="text" formControlName="username" [(ngModel)]="strUserName" name="strUserName" placeholder="Usuario">
          <span class="focus-border"></span>
        </div>
        <mat-hint class="formatError" style="color: red;" *ngIf="submitted && loginForm.controls.username.hasError('required')">*Campo necesario  </mat-hint>    
        <mat-hint class="formatError" style="color: red;" *ngIf="emailError">Introduzca un email válido</mat-hint>
      </section>
   
      <div>
        <button mat-raised-button (click)="submitLogin()" class="miBoton">Acceder</button>
      </div>
     
      </fieldset>
   </form>
  
  <br><br> 
  
  </mat-card>
  