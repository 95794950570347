import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  // private rolId: string = environment.rolAdmin;

  constructor(private storageService: StorageService,private router: Router) { }

  canActivate() {    
    if (this.storageService.isAuthenticated()) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page
    // this.router.navigate(['/login']);
    this.storageService.logout();
    window.open(window.location.origin+"/login", "_self")
    return false;
  }
}