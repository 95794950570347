
<div style="width: 100%; text-align: center;">
    <mat-select [(ngModel)]="intIdRebano" name="intIdRebano"  (selectionChange)="selectRebano()"  placeholder="Rebaño">
        <mat-option name="intIdRebano" [value]="1">Rebaño 1</mat-option>
        <mat-option name="intIdRebano" [value]="2">Rebaño 2</mat-option>
        <mat-option name="intIdRebano" [value]="3">Rebaño 3</mat-option>
      </mat-select>
</div>

<br><br>

<div style="width: 100;; text-align: center; color: red; font-weight: 600;" *ngIf="isSelected">
Por favor, seleccione un rebaño
</div>
<br *ngIf="isSelected">

<div class="form-actions">
    <div class="text-right">
      <button mat-raised-button (click)="guardar()" class="btn-confirm">Guardar</button>
      <button mat-raised-button (click)="cancelar()" class="btn-cancel">Cancelar</button>
    </div>
  </div>