import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { DateAdapter, MatDialog, MatPaginator, MatRadioChange, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { AuxEstado } from 'src/app/models/aux-estado';
import { Rebano } from 'src/app/models/ganaderia/rebano';
import { AdministradorService } from 'src/app/services/administrador.service';
import { NotificacionComponent } from '../Ventanas emergentes/notificacion/notificacion.component';

@Component({
  selector: 'app-listado-rebanos',
  templateUrl: './listado-rebanos.component.html',
  styleUrls: ['./listado-rebanos.component.scss']
})
export class ListadoRebanosComponent implements OnInit {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  showSpinner: boolean = true;
  data:Rebano[]=[];
  dataTodo:Rebano[]=[];
  displayedColumns = ['intId','strNombre','strTipoNombre','strUbicacion','strActivo'];
  dataSource ;
  filtro:string;

  listFiltrada:Rebano[]=[];

  dataExcel:datosExcelListado[]=[];
  teclaAlt:any;
  otraTecla:any;

  idInst:any;
  numRegistros:any;
  filtrosEstado:AuxEstado[]=[];
  // dataFilterEst:Tarjeta[]=[];
  // dataFilterVinc:Tarjeta[]=[];
  selectEstado:any;

  constructor(private miservicio:AdministradorService,public datepipe: DatePipe,
    private dateAdapter: DateAdapter<Date>,public dialog: MatDialog,private router: Router) {
    this.dateAdapter.setLocale('es');   
   }

  ngOnInit() {
    this.filtrosEstado.push({intId:1, strNombre:"Activos", strValor:"S", booChecked:true}, {intId:2, strNombre:"Inactivos", strValor:"N", booChecked:false}, {intId:3, strNombre:"Todos", strValor:"T", booChecked:false});
    this.selectEstado=1;
    this.listar();
  }

  // detalleTarjeta(e:Tarjeta){
  //   let extra: NavigationExtras = {
  //     queryParams: {
  //       'intId':e.intClienteId,
  //       'intTarjeta':e.intId,
  //     }
  //   }
  //   this.router.navigate(["detalle-tarjeta"],extra);
  // }

  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) { 
    this.otraTecla=event.keyCode;  
    
    if(this.teclaAlt===78 && this.otraTecla===18){
      // this.nuevo();
    }else if(this.teclaAlt===88 && this.otraTecla===18){
      // this.exportAsXLSX();
    }else if(this.teclaAlt===66 && this.otraTecla===18){
      var myEl = document.getElementById('miFiltro');
      myEl.focus();
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    //alert(event.keyCode)
    this.teclaAlt=event.keyCode;    
  }

  public listar(){
    this.miservicio.getRebanos(0,0,'','').subscribe(datos=>{
      this.showSpinner=false;
      // console.log(datos)
      if(datos.miRespuesta.booOK){
        this.listFiltrada=datos.miListRebanos; 
        this.dataTodo=datos.miListRebanos;
        this.data=datos.miListRebanos.filter(i=>i.strActivo.toLowerCase()==="S".toLowerCase());

        this.numRegistros=this.data.length;
        this.dataSource=new MatTableDataSource(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator=this.paginator;
        this.data=this.dataTodo;
      }
    })
  }

  radioChange(event: MatRadioChange) {

    switch(event.value){
      case "S":
        this.selectEstado=1;
        break;

      case "N":
        this.selectEstado=2;
        break;

      case "T":
        this.selectEstado=3;
      break;

    }

    this.buscar();
  }

  radioChange2(event: MatRadioChange) {
    switch(event.value){
      case "S":
        this.selectEstado=4;
        break;

      case "N":
        this.selectEstado=5;
        break;

      case "T":
        this.selectEstado=6;
      break;

    }

    this.buscar();
  }

  getColor(e) { 
    switch (e) {
      case 'S':
        return '#5cd65c';
      case 'N':
        return '#ff6666';
    }
  }

  buscar(){  
    this.dataTodo=this.data;
    if(this.filtro!=null){
      this.data=this.data.filter(i=>i.strNombre.replace(/\s/g, "").toLowerCase().includes(this.filtro.replace(/\s/g, "").toLowerCase()) || 
                                 i.strTipoNombre.replace(/\s/g, "").toLowerCase().includes(this.filtro.replace(/\s/g, "").toLowerCase()));
      
    }

    if(this.selectEstado!=0){
      switch(this.selectEstado){
        case 1:
          this.data=this.data.filter(i=>i.strActivo.toLowerCase()==="S".toLowerCase());
          break;
  
        case 2:
          this.data=this.data.filter(i=>i.strActivo.toLowerCase()==="N".toLowerCase());
          break;
  
        case 3:
        break;
      }
    }

    this.numRegistros=this.data.length;
    this.listFiltrada=this.data;
    this.dataSource=new MatTableDataSource(this.data);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator=this.paginator;
    this.data=this.dataTodo;
  }

  windowNotif(booBoton, booBtnError, btnOK, strMensaje, strParrafo2){
    let dialogRef = this.dialog.open(NotificacionComponent, {
      width: '600px',
      data:{
        texto:strMensaje,
        texto2:strParrafo2,
        boton:booBoton,
        btnError:booBtnError,
        btnOK:btnOK
      }
    });
    dialogRef.afterClosed().subscribe(result =>{
      // return result;
    })
  }

  // exportAsXLSX():void {
  //   this.listFiltrada.forEach(e => {
  //     this.dataExcel.push({
  //       Tarjeta:e.strNombre,
  //       Codigo:e.strCodigoTus,
  //       Nombre:e.strClienteNombre,
  //       Apellidos:e.strClienteApellidos,
  //       Dni:e.strClienteDni,
  //       Activo:e.strActivo,
  //       Fecha_registro:this.datepipe.transform(e.datFechaRegistro, 'dd-MM-yyyy')      
  //     })
  //   });
  //   this.miservicio.exportAsExcelFile(this.dataExcel, 'Listado de tarjetas');

  //   this.dataExcel.splice(0, this.dataExcel.length)
  // }

  deleteFilter(){
    //Refrescar la pagina
    window.location.reload();
  }

}

export interface datosExcelListado {
  Tarjeta:any;
  Codigo:any;
  Nombre:any;
  Apellidos:any;
  Dni:any;
  Activo:any;
  Fecha_registro:any;
}