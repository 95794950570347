export class Operador {

    public intId?:number;
    public strNombre?:string;
    public strApellidos?:string;
    public strDni?:string;
    public strEmail?:string;      
    public strUserId?:string;
    public strActivo?:string;
    public datFechaRegistro?:Date;
    public datFechaActualizacion?:Date;
    public strUserIdActualizacion?:string;
    public strPassword?:string;
    public strConfirmPassword:string;
    public intTipoUsuario:number=2;
    public strTelefono1?:string;

    public strNewPassword?:string;

}
