import {User} from "./user.model";
import { Token } from './token';

export class Session {
  public strToken?: string;
  public strMensaje:string;
  // public strCookie?:string;
  public expires?:number;
  // public intMenuId?:number;
  public user: User; 

  public strNombre?:string;
  public strNombreTitulo?:string;
  public token: Token = new Token();

  //guardo url_rest en sesion
  public strUrlRest:string;

  // miListComponente:Componente[]=[];
  //miListMenuResult:Menu[]=[];
  //miListMenuGrupo1:Menu[]=[];
  //miGrupo1: Menugrupo;
  
}
