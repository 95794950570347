
<mat-toolbar >
  <mat-toolbar-row>
      <span *ngIf="abierto" (click)="closeNav()" id="main" class="menu">
        <span><mat-icon>menu</mat-icon></span>        
      </span>
                     
      <span *ngIf="cerrado" class="menu" (click)="openNav()"><mat-icon>menu</mat-icon></span>

    <!-- <div><div class="instalacion" routerLink="../inicio" routerLinkActive="active-link">Inicio</div></div> -->

    <img src="../../../assets/LogoExpGanadera.png" height="65" class="miLogotus">

  </mat-toolbar-row>
</mat-toolbar>

<div class="miMenu22" id="miSidenav">
  <br><br>
  <a>Hola, {{loginUserData.strUserName}}</a>
  <a routerLink="../listado-animales" routerLinkActive="active-link"> <div><span>Animales</span> </div> </a>
  <a routerLink="../explotaciones" routerLinkActive="active-link"> <div><span>Explotaciones</span> </div> </a>
  <a routerLink="../rebanos" routerLinkActive="active-link"> <div><span>Rebaños</span> </div> </a>
  <a routerLink="../especies" routerLinkActive="active-link"> <div><span>Especies</span> </div> </a>
  <a routerLink="../razas" routerLinkActive="active-link"> <div><span>Razas</span> </div> </a>
  <!-- <a routerLink="../operarios" routerLinkActive="active-link"> <div><img src="../../../assets/supervisor.png" width="22" height="22"><span>Operarios</span> </div> </a> -->
  <!-- <a routerLink="../totems" routerLinkActive="active-link"> <div><img src="../../../assets/desktop.png" width="22" height="22"><span>Totems</span> </div> </a> -->
  <!-- <a routerLink="../historial-recargas" routerLinkActive="active-link"> <div><img src="../../../assets/book.png" width="22" height="22"><span>Historial de recargas</span> </div> </a> -->
  <!-- <a routerLink="../recargas-totem" routerLinkActive="active-link"> <div><img src="../../../assets/book.png" width="22" height="22"><span>Recargas totem</span> </div> </a> -->
  <!-- <a routerLink="../configuracion" routerLinkActive="active-link"> <div><img src="../../../assets/build.png" width="22" height="22"><span>Configuración</span> </div> </a> -->
  <a  (click)="logout()" class="boton_salir"> <div><img src="../../../assets/power_settings_new.png" width="22" height="22"><span>Salir</span> </div> </a>
</div>

<!-- <div class="miMenu22" id="miSidenav" *ngIf="operario">
  <br><br>
  <a>Hola, {{loginUserData.strUserName}}</a>
  <a routerLink="../list-clientes" routerLinkActive="active-link"> Clientes</a>
  <a routerLink="../list-tarjetas" routerLinkActive="active-link"> Tarjetas</a>
  <a routerLink="../historial-recargas" routerLinkActive="active-link"> Historial de recargas</a>  
  <a  (click)="logout()" class="boton_salir"> <div><img src="../../../assets/power_settings_new.png" width="22" height="22"><span>Salir</span> </div> </a>
</div>

<div class="miMenu22" id="miSidenav" *ngIf="cliente">
  <br><br>
  <a >Hola, {{loginUserData.strUserName}}</a>
  <a routerLink="../datos-cliente" routerLinkActive="active-link"> <img src="../../../assets/delete.png" width="22" height="22"> Mis datos</a>
  <a routerLink="../tarjetas" routerLinkActive="active-link"> <img src="../../../assets/credit_card.png" width="22" height="22"> Mis tarjetas</a>
  <a routerLink="../mis-recargas" routerLinkActive="active-link"> <img src="../../../assets/book.png" width="22" height="22"> Mis recargas</a>
  <a routerLink="../recargar" routerLinkActive="active-link"> <img src="../../../assets/assignment_ind.png" width="22" height="22"> Mis operaciones</a>
  <a  (click)="logout()" class="boton_salir"> <div><img src="../../../assets/power_settings_new.png" width="22" height="22"><span>Salir</span> </div> </a>  
</div> -->

<!-- <nav class="miSpinner" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</nav> -->

<div class="containerLoader" *ngIf="showSpinner">
  <div class="wrapper">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="shadow"></div>
    <div class="shadow"></div>
    <div class="shadow"></div>
  </div>
</div>


