import { Injectable } from '@angular/core';
import { SessionPagosTus } from '../models/session-pagos-tus';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SotaregePagosTusService {
  private localStorageService;
  private currentSessionP : SessionPagosTus = null;
  strCookie: string = environment.strCookieRecargaId;  

  constructor() {
    this.localStorageService = localStorage;
    this.currentSessionP = this.loadSessionDataP();
  }

  setSessionPagos(sessionP: SessionPagosTus): void {
    this.currentSessionP = sessionP;
    this.localStorageService.setItem(this.strCookie, JSON.stringify(sessionP));
  }

  loadSessionDataP(): SessionPagosTus{
    var sessionStr = this.localStorageService.getItem(this.strCookie);
    return (sessionStr) ? <SessionPagosTus> JSON.parse(sessionStr) : null;
  }

  getCurrentSessionP(): SessionPagosTus {
    return this.currentSessionP;
  }

  removeCurrentSessionP(): void {
    this.localStorageService.removeItem(this.strCookie);
    this.currentSessionP = null;
  }
  
  }
  