
import {Injectable} from "@angular/core";
import { Router, NavigationExtras } from '@angular/router';
import {Session} from "../models/session.model";
import {User} from "../models/user.model";
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class StorageService {

  private localStorageService;
  private currentSession : Session = null;

  public status: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  
  constructor(private router: Router) {
    this.localStorageService = localStorage;
    this.currentSession = this.loadSessionData();
  }

  changeData(value: any) {
    this.status.next(value);
  }

  setCurrentSession(session: Session): void {
    this.currentSession = session;
    this.localStorageService.setItem('loginTus', JSON.stringify(session));
  }

  loadSessionData(): Session{
    var sessionStr = this.localStorageService.getItem('loginTus');
    return (sessionStr) ? <Session> JSON.parse(sessionStr) : null;
  }

  getCurrentSession(): Session {
    return this.currentSession;
  }

  removeCurrentSession(): void {
    this.localStorageService.removeItem('loginTus');
    this.currentSession = null;
  }

  getCurrentUser(): User {
    var session: Session = this.getCurrentSession();
    return (session && session.user) ? session.user : null;
  };

  isAuthenticated(): boolean {
    return (this.getCurrentToken() != null) ? true : false;
  };

  getCurrentToken(): string {
    var session = this.getCurrentSession();
    return (session && session.token.access_token) ? session.token.access_token : null;
  };

  getCurrentUrlRest(): string {
    var session = this.getCurrentSession();
    return (session && session.strUrlRest) ? session.strUrlRest : null;
  };

  logout(): void{
    // window.location.href='http://infiteccontrol.infitec.es/servicios/solicita?est='+ this.getCurrentSession().strEstablecimientoId +'&pro='+  this.getCurrentSession().strProductoId+'';

    this.removeCurrentSession();
    // this.router.navigate(["/login"]);
  }

}
