export class TipoTarjeta {

    public intId?:number;
    public strNombre?:string;
    public strIdExterno?:string;
    public strActivo?:string;
    // public strUsaTitulo1?:string;
    // public strUsaTitulo2?:string;

}
