import { Component, OnInit, OnDestroy, OnChanges, AfterContentInit, ViewChild, ElementRef } from '@angular/core';
//import { CRespuesta } from './shared/crespuesta';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { CRespuestaService } from './shared/CRespuesta.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CRespuesta } from './shared/crespuesta';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material';
import { User } from 'src/app/models/user.model';
import { StorageService } from 'src/app/services/storage.service';
import { NotificacionComponent } from '../Ventanas emergentes/notificacion/notificacion.component';
import { Token } from 'src/app/models/token';
import { Session } from 'src/app/models/session.model';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit  {


public miCRespuesta:CRespuesta=new CRespuesta();
isLoginError : boolean = false;
texto:string;
public userAcces:User[]=[];
strUserName:string;
strPassword:string;
token:string;
miSesion:Session=new Session();
misDatosSesion:Token=new Token();

showSpinner: boolean = false;
showForm=true;
mostrar:boolean=true;

public loginForm: FormGroup;
public submitted: Boolean = false;

mostrarPassword:boolean=true;
ocultarPassword:boolean=false;

title:string;

private rolAdmin=environment.IdRolAdmin
private rolCliente=environment.IdRolCliente
private rolOperario=environment.IdRolOperario

hide=true;

constructor(private formBuilder: FormBuilder,private dialog: MatDialog,
  private storageService: StorageService,private titleService: Title,
  private router: Router,
  private CRespuestaService:CRespuestaService) { }

ngOnInit() {
  this.loginForm  = this.formBuilder.group({

    username: new FormControl('', Validators.compose([
      Validators.required,
      Validators.maxLength(40),
      Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
    ])),

    password: new FormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(6),
      //Contraseña: minimo 1 mayuscula, 1 minuscula, 1 numero y 1 caracter especial
      Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,}'),
    ])),

  });

  // this.solicitaDatosConfiguracion();
}

solicitaDatosConfiguracion(){
  this.CRespuestaService.cargarDatosIniciales().subscribe(datosIni=>{
    this.CRespuestaService.getDatosInicialesApp(datosIni.url_rest).subscribe(datos=>{
      // console.log(datos)
  
      let newTitle:string;
      newTitle=datos[0].strNombreTitulo;
      this.titleService.setTitle(newTitle);
    })
  })
}

  
submitLogin(){
  this.submitted = true;
  // this.isLoginError = true;

  if(this.loginForm.valid){
    this.submitted = false;
    this.showSpinner=true;
    this.showForm=false;

    //Solicito url_rest
    this.CRespuestaService.cargarDatosIniciales().subscribe(datosConfig=>{
      this.miSesion.strUrlRest=datosConfig.url_rest;
    })

    this.CRespuestaService.findCRespuesta(this.strUserName,this.strPassword).subscribe((Response1:any)=>{
      // console.log(Response1)
      if(Response1.userName!=undefined){

        let miUserName=Response1.userName;
        let miToken=Response1.access_token;
        // this.token=Response1.access_token;      

        // this.misDatosSesion=Response1 as Token;
        this.miSesion.token=Response1;
        this.miSesion.strToken=Response1.access_token;
  
        this.CRespuestaService.findSession(miUserName,this.strPassword).subscribe((Response2:any) =>{
          // console.log(Response2)
          if(Response2.user.strUserName!=undefined){
            this.miSesion.user=Response2.user;
            this.correctLogin(this.miSesion);
  
              // /********************* Aqui recuperaro los datos del nombre y titulo de la app de la sesion  ******************/
              // this.CRespuestaService.getConfiguraciones(Response2.strToken).subscribe((Response3:any) => { 
              //   // console.log(Response3)
              //   if(Response3.length>0){

              //     //Relleno los datos de sesion
              //     this.miSesion.strNombre=Response3[0].strNombre;
              //     this.miSesion.strNombreTitulo=Response3[0].strNombreTitulo;
              //     // console.log(this.miSesion)

              //     this.correctLogin(this.miSesion);
              //   }
                
              // });

          }
        })
       }
     },
     (err : HttpErrorResponse)=>{
      //  this.isLoginError = true;
       //Usuario o contraseña incorrectos
       let dialogRef = this.dialog.open(NotificacionComponent, {
        width: '600px',
        data:{
          texto:"Usuario o contraseña incorrectos",
          boton:false,
          btnError:true,
          btnOK:false
        }
      });
      this.showSpinner=false;
      this.showForm=true;
     });
    //  this.isLoginError = false;
     

  }
  
 }

 public getTitle(){
  this.title = document.title = this.storageService.getCurrentSession().strNombreTitulo;
  //console.log(this.title);
}

  private correctLogin(data: Session){
    this.storageService.setCurrentSession(data);

    this.showSpinner = true;
    this.mostrar=false;
    // console.log(this.storageService.getCurrentSession())

    this.router.navigate(['listado-animales']);

    //this.getTitle();

    // switch(data.user.strRolId){
    //   case this.rolAdmin:
    //     this.router.navigate(['list-clientes']);
    //     break;
      
    //   case this.rolCliente:
    //     this.router.navigate(['datos-cliente']);
    //     break;

    //   case this.rolOperario:
    //     this.router.navigate(['list-clientes']);
    //     break;
    // }
  }

  createUserLogin(){
    this.router.navigate(['/create-user'])
  }


  showPassword(){
    this.mostrarPassword=false;
    this.ocultarPassword=true;
  }

  hiddenPassword(){
    this.mostrarPassword=true;
    this.ocultarPassword=false;
  }

}
