import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthAdminGuard implements CanActivate {

  private rolAdmin: string = environment.IdRolAdmin;

  constructor(private storageService: StorageService,private router: Router) { }

  canActivate() {
    //console.log(this.storageService.isAuthenticated());
    let loginUserData = this.storageService.getCurrentUser();
    if (this.storageService.isAuthenticated() && this.userAuthorized()) {
      // logged in so return true
      return true;
    }
    this.storageService.logout();
    //this.router.navigate(['/login']);
    window.open(window.location.origin+"/login", "_self")
    return false;
  }

  userAuthorized(){
    let loginUserData = this.storageService.getCurrentUser();

    switch (loginUserData.strRolId){
      case this.rolAdmin:
        return true;

      default:
        return false;
    }
  }
}